import { useState } from 'react';
import { Typography, Grid, Theme, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Text } from '@volkswagen-onehub/components-core';

import { useWindowDimensions } from '../../../utils/hooks/useWindowDimensions';
import { IDetailsSectionType, IGroup } from '../../../types';
import { TechnicalTable } from '../../Table';

function a11yProps(index: any) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`
  };
}

export type ITechnicalsProps = {
  type: IDetailsSectionType;
  children?: React.ReactNode;
  data: IGroup;
};

export const Technicals = (props: ITechnicalsProps) => {
  const classes = useStyles();

  const [selectedMobileCol, setSelectedMobileCol] = useState(0);
  const { isOnMobileBreakpoint } = useWindowDimensions();

  const handleChangeIndex = (index: number) => {
    setSelectedMobileCol(index);
  };

  if (!props.data) {
    return null;
  }

  const gridLength = Array.isArray(props.data.models) && props.data.models ? props.data.models.length : 3;

  return (
    <div>
      {isOnMobileBreakpoint ? (
        <Tabs
          value={selectedMobileCol}
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          aria-label="action tabs example"
          scrollButtons
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {Array.isArray(props.data.models) &&
            props?.data?.models?.map((model, index: number) => (
              <Tab
                key={`${model.name}-${index}`}
                onClick={() => handleChangeIndex(index)}
                label={model.name}
                className={classes.tab}
                {...a11yProps(index)}
              />
            ))}
        </Tabs>
      ) : (
        <Grid container className={classes.preTable}>
          <Grid item xs={3} className={classes.preTableCell1}>
            <Typography variant="h5">
              <Text bold>{props.data.name}</Text>
            </Typography>
          </Grid>
          {Array.isArray(props.data.models) &&
            props?.data?.models?.map((model) => (
              <Grid item xs={9 / gridLength} className={classes.preTableCell2} key={`top-${model.name}`}>
                <div>{model.name}</div>
              </Grid>
            ))}
        </Grid>
      )}

      <div>
        {isOnMobileBreakpoint ? (
          <Typography variant="h5" className={classes.tableName}>
            <Text bold>{props.data.name}</Text>
          </Typography>
        ) : null}
        {props?.data?.categories?.map((category) => (
          <div key={category?.name}>
            <div style={{ margin: '16px 0 14px 16px' }}>
              <Typography variant="h5" className={classes.tableTitle}>
                <Text bold>
                  <div dangerouslySetInnerHTML={{ __html: category?.name }} />
                </Text>
              </Typography>
            </div>
            <TechnicalTable
              type="technicals"
              category={category}
              items={props.data.items}
              selectedMobileCol={selectedMobileCol}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  preTable: {
    marginTop: '48px',
    borderBottom: '2px solid #001E50',
    '& :last-child': {
      borderRight: '2px solid #ffffff'
    },
    position: 'sticky',
    top: '50px',
    zIndex: 1,
    background: '#fff'
  },

  preTableCell1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRight: '2px solid #001E50',
    '& :last-child': {
      fontSize: 24,
      paddingLeft: 10
    }
  },
  preTableCell2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 700,
    borderRight: '2px solid #DFE4E8',
    padding: '20px 16px'
  },

  tableName: {
    padding: '0 0 14px 16px',
    borderBottom: '2px solid #001E50'
  },

  tabs: {
    margin: '40px 0',
    width: '95vw',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  tab: {
    textTransform: 'none',
    padding: '10px 16px',
    height: 60,
    width: 220
  },
  tabBreak: {
    backgroundColor: '#DFE4E8',
    marginTop: 8,
    width: 1,
    height: 32
  },
  tableTitle: {
    fontSize: 20,
    fontWeight: 700,
    display: 'block',
    marginRight: 9,
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block'
    }
  },
  tableSubTitle: {
    fontSize: 14,
    fontWeight: 400,
    display: 'inline-block'
  }
}));
