import config from '../configuration/config';

const baseUrl = config.assetsBaseUrl ?? '';

export const assetUrl = (path: string | null): string => (path?.at(0) === '/' ? baseUrl + path : '' + path);

export const applyAssetUrl = (obj: any) => {
  if (!baseUrl || !obj) {
    return obj;
  }

  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (/^.+Url$/.test(prop)) {
        const path = obj[prop];
        if (typeof path === 'string' && /\/assets\//.test(path)) {
          obj[prop] = assetUrl(path);
        }
      } else if (typeof obj[prop] === 'object') {
        applyAssetUrl(obj[prop]);
      }
    }
  }

  return obj;
};
