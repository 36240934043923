// @ts-nocheck
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import * as React from 'react';

import { ISection, IDetailsSectionType } from '../../types';
import { ICalculatorResults } from '../../contexts/types';
import { PdfTable } from './PdfTable';

export type IPdfModelsAndFeaturesProps = {
  type: IDetailsSectionType;
  children?: React.ReactNode;
  data: ISection;
  calculationResults: ICalculatorResults;
};

export const PdfModelsAndFeatures = (props: IPdfModelsAndFeaturesProps) => {
  const equipmentLinesRowWidth = 20 + props?.data?.equipmentLines.length * 10;
  const widthCell1 = `${100 - (equipmentLinesRowWidth <= 70 ? equipmentLinesRowWidth : 70)}%`;

  return (
    <View style={styles.section}>
      <View style={styles.preTable}>
        {props?.data?.equipmentLines?.map((equipment: string, index: number) => (
          <View
            key={`top-${equipment}`}
            style={{
              ...styles.preTableCell,
              marginLeft: !index ? widthCell1 : 0,
              borderLeft: `2px solid ${index === 0 ? '#001E50' : '#DFE4E8'}`
            }}
          >
            <Text style={{ fontFamily: 'VWHead', fontSize: '12px' }}>{equipment}</Text>
          </View>
        ))}
      </View>

      <View>
        {props?.data?.groups?.map((group, index) => (
          <View key={group?.name}>
            <View style={{ margin: '4px 0 4px 8px' }}>
              <Text style={{ fontFamily: 'VWHead', fontSize: '12px' }}>{group?.name}</Text>
            </View>
            <PdfTable
              type={props.type}
              section={props.data}
              group={group}
              calculationResults={props.calculationResults}
            />
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    marginHorizontal: 24
  },
  preTable: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '2px solid #001E50'
  },

  preTableCell: {
    display: 'flex',
    flexGrow: 1,
    padding: '5px 8px',
    justifyContent: 'center',
    alignItems: 'center'
  },

  tableTitle: {
    fontSize: 20,
    marginRight: 9,
    display: 'inline-block'
  }
});
