import { Component } from 'react';
import * as React from "react";

declare module 'react' {
  interface Attributes {
    wrap?: any;
  }
}

export class IntegratorFooter extends Component<any, any> {
  // @ts-ignore
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
      <div id="vwa_d6_cms_integrator_ssr_container">
        <div className="StyledIntegratorRoot-sc-140bz40 bzGtOa">
          <footer className="sc-kgoBCf cxotBu">
            <div className="StyledContainer-sc-nhelkh cKOuwF StyledBaseContainer-sc-1s9pby4 ifjRIm">
              <div className="StyledChildWrapper-sc-9dbn6y lgYoaz">
                <div className="StyledOverflowWrapper-sc-vtluxa eaoJHN" wrap="always">
                  <div className="StyledContainer-sc-1eq0g4k kczMzx" wrap="always">
                    <div className="StyledChildWrapper-sc-1ayh1js dJzoMT" wrap="always">
                      <div className="sc-cSHVUG jBhlIh">
                        <div className="sc-kAzzGY lefSlT">
                          <div className="sc-dxgOiQ kZmrcN">
                            <div className="sc-ckVGcZ bLsgMP">
                              <div className="StyledTextComponent-sc-1h30k8b bALzzm">
                                Volkswagen w Polsce i na świecie
                              </div>
                            </div>
                            <ul className="sc-jKJlTe kjlAOl">
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="https://www.volkswagen.pl:443/pl/swiat-volkswagena/ochrona-srodowiska/bluemotion-technologies.html"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Ochrona Środowiska</span></a>
                                </div>
                              </li>
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="https://www.volkswagen.pl:443/pl/swiat-volkswagena/ochrona-srodowiska/dane-o-emisji-co2.html"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Dane o emisji CO₂</span></a>
                                </div>
                              </li>
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="https://www.volkswagen.pl:443/pl/swiat-volkswagena/ochrona-srodowiska/wltp.html"><span
                                    className="StyledText-sc-bfxh29 gKczTW">WLTP – zużycie paliwa i emisja CO₂</span></a>
                                </div>
                              </li>
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="http://webspecial.volkswagen.de/vwinfotainment/pl/pl/index/do-pobrania.html#/"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Zaktualizuj nawigację</span></a>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="sc-dxgOiQ kZmrcN">
                            <div className="sc-ckVGcZ bLsgMP">
                              <div className="StyledTextComponent-sc-1h30k8b bALzzm"></div>
                            </div>
                            <ul className="sc-jKJlTe kjlAOl">
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd" href="http://volkswagenhome.pl/"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Volkswagen Home</span></a>
                                </div>
                              </li>
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd" href="http://www.volkswagenag.com/"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Volkswagen AG</span></a>
                                </div>
                              </li>
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="https://www.vwuzytkowe.pl/pl.html"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Volkswagen Samochody Dostawcze</span></a>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="sc-dxgOiQ kZmrcN">
                            <div className="sc-ckVGcZ bLsgMP">
                              <div className="StyledTextComponent-sc-1h30k8b bALzzm">
                                Skontaktuj się z nami
                              </div>
                            </div>
                            <ul className="sc-jKJlTe kjlAOl">
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="https://www.volkswagen.pl/app/formularze/vw-pl/Jazdaprobna/pl"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Zapisz się na jazdę próbna </span></a>
                                </div>
                              </li>
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="https://www.volkswagen.pl/app/formularze/vw-pl/Zapytanie/pl"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Zapytaj o ofertę</span></a>
                                </div>
                              </li>
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd" href="https://osb.volkswagen.pl/"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Umów się na serwis</span></a>
                                </div>
                              </li>
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="https://www.volkswagen.pl/app/szukaj/vw-pl/pl"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Znajdź dealera</span></a>
                                </div>
                              </li>
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="https://www.volkswagen.pl:443/pl/kontakt.html"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Zadzwoń lub napisz do nas</span></a>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="sc-dxgOiQ kZmrcN">
                            <div className="sc-ckVGcZ bLsgMP">
                              <div className="StyledTextComponent-sc-1h30k8b bALzzm">Social Media</div>
                            </div>
                            <ul className="sc-jKJlTe kjlAOl">
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="https://www.facebook.com/VolkswagenPolska"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Facebook</span></a>
                                </div>
                              </li>
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="https://www.instagram.com/vwpolska/?hl=pl"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Instagram</span></a>
                                </div>
                              </li>
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="https://www.youtube.com/user/VolkswagenPolska"><span
                                    className="StyledText-sc-bfxh29 gKczTW">YouTube</span></a>
                                </div>
                              </li>
                              <li>
                                <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                  <a className="StyledLink-sc-1jhtndy bRAihd"
                                     href="https://twitter.com/vwpress_pl?lang=pl"><span
                                    className="StyledText-sc-bfxh29 gKczTW">Twitter</span></a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="sc-eNQAEJ FYksS">
                          <div className="sc-hMqMXs ejiNUn">
                            <div className="StyledTextComponent-sc-1h30k8b ehLThv">© Volkswagen 2019</div>
                          </div>
                          <div className="sc-kEYyzF iNVrmh">
                            <div className="sc-kkGfuU AlipF">
                              <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                <a className="StyledLink-sc-1jhtndy bRAihd"
                                   href="https://www.volkswagen.pl:443/pl/dokumenty/polityka-prywatnosci.html"><span
                                  className="StyledText-sc-bfxh29 gKczTW">Polityka prywatności</span></a>
                              </div>
                            </div>
                            <div className="sc-kkGfuU AlipF">
                              <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                <a className="StyledLink-sc-1jhtndy bRAihd"
                                   href="https://www.volkswagen.pl:443/pl/dokumenty/regulamin-serwisu.html"><span
                                  className="StyledText-sc-bfxh29 gKczTW">Regulamin serwisu</span></a>
                              </div>
                            </div>
                            <div className="sc-kkGfuU AlipF">
                              <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                <a className="StyledLink-sc-1jhtndy bRAihd"
                                   href="https://www.volkswagen.pl:443/pl/dokumenty/polityka-plikow-cookies.html"><span
                                  className="StyledText-sc-bfxh29 gKczTW">Polityka plików cookies</span></a>
                              </div>
                            </div>
                            <div className="sc-kkGfuU AlipF">
                              <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                <a className="StyledLink-sc-1jhtndy bRAihd"
                                   href="https://www.volkswagen.pl/cofnijzgode"><span
                                  className="StyledText-sc-bfxh29 gKczTW">Formularz cofnięcia zgód</span></a>
                              </div>
                            </div>
                            <div className="sc-kkGfuU AlipF">
                              <div className="StyledTextComponent-sc-1h30k8b ffIqFt">
                                <a className="StyledLink-sc-1jhtndy bRAihd"
                                   href="https://www.volkswagen.pl:443/pl/dokumenty/id/pbt/id3tc.html"><span
                                  className="StyledText-sc-bfxh29 gKczTW">Regulamin Prebookingu ID.3 1st</span></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="StyledChildWrapper-sc-1ayh1js dJzoMT" wrap="always">
                      <div className="sc-chPdSV kWiadU">
                        <div className="sc-kGXeez kYbQgq">
                          <div className="sc-kpOJdX kArKFJ">Zastrzeżenia prawne Volkswagen Group Polska Sp. z o.o.</div>
                          <div className="StyledOverflowWrapper-sc-vtluxa eaoJHN" wrap="always">
                            <div className="StyledContainer-sc-1eq0g4k kOXiUD" wrap="always">
                              <div className="StyledChildWrapper-sc-1ayh1js hRCTcH" wrap="always">
                                <div className="sc-bdVaJa jzBcqt">
                                  <div className="StyledTextComponent-sc-1h30k8b lkONwG">
                                    <p>Wszelkie prezentowane informacje, w szczególności zdjęcia, wykresy, specyfikacje,
                                      opisy, rysunki lub parametry techniczne, nie stanowią oferty w rozumieniu Kodeksu
                                      cywilnego oraz nie są wiążące i mogą ulec zmianie bez wcześniejszego powiadomienia.
                                      Prezentowane informacje nie stanowią zapewnienia w rozumieniu art. 556(1)§2 Kodeksu
                                      cywilnego. Volkswagen zastrzega sobie możliwość wprowadzenia zmian w prezentowanych
                                      wersjach. Przedstawione detale wyposażenia mogą różnić się od specyfikacji
                                      przewidzianej
                                      na rynek polski. Zamieszczone zdjęcia mogą przedstawiać wyposażenie opcjonalne.
                                      Wiążące
                                      ustalenie ceny, wyposażenia i specyfikacji pojazdu następuje w umowie sprzedaży, a
                                      określenie parametrów technicznych zawiera świadectwo homologacji typu pojazdu.
                                      Zastrzegamy sobie prawo do zmian i pomyłek. Wszelkie informacje prezentowane w
                                      niniejszym cenniku są aktualne na dzień publikacji materiału.</p>
                                    <p>W celu uzyskania najnowszych informacji prosimy kontaktować się z Autoryzowanym
                                      Dealerem Marki Volkswagen. </p>
                                    <p>Z uwagi na ograniczenia technik drukarskich lub parametrów ekranu, na którym obraz
                                      jest
                                      wyświetlany, kolory przedstawione w niniejszym materiale mogą nieznacznie różnić się
                                      od
                                      faktycznych kolorów lakieru i materiałów. </p>
                                    <p>Zawartość domówionego pakietu lub opcji może zmienić elementy wyposażenia
                                      standardowego
                                      – w niektórych przypadkach bezzwrotnie zastępując elementy wyposażenia
                                      standardowego. </p>
                                    <p>Wszystkie produkowane obecnie samochody marki Volkswagen są wykonywane z materiałów
                                      spełniających pod względem możliwości odzysku i recyklingu wymagania określone w
                                      normie
                                      ISO 22628 i są zgodne z europejskimi świadectwami homologacji wydanymi wg dyrektywy
                                      2005/64/WE. Volkswagen Group Polska sp. z o.o. podlega obowiązkowi zapewnienia
                                      wszystkim
                                      użytkownikom samochodów Volkswagen sieci odbioru pojazdów po wycofaniu ich z
                                      eksploatacji, zgodnie z wymaganiami ustawy z 20 stycznia 2005 r. o recyklingu
                                      pojazdów
                                      wycofanych z eksploatacji. Więcej informacji dotyczących ekologii znajdą Państwo na
                                      stronach:
                                      https://www.volkswagen.pl/pl/ochrona-srodowiska/recykling-samochodow.html</p>
                                    <p>Prezentowane ceny są wyłącznie cenami rekomendowanymi i obejmują podatek VAT (23
                                      %).</p>
                                    <p>Czas promocji i liczba aut objętych promocją ograniczone.</p>
                                    <p>Zawartość domówionego pakietu lub opcji może zmienić elementy wyposażenia
                                      standardowego
                                      – w niektórych przypadkach bezzwrotnie zastępując elementy wyposażenia
                                      standardowego.</p>
                                    <p>Od 1 września 2018 r. wszystkie nowe pojazdy wprowadzane do obrotu w Unii
                                      Europejskiej
                                      muszą być badane i homologowane zgodnie z procedurą WLTP określoną w rozporządzeniu
                                      Komisji (UE) 2017/1151. WLTP zapewnia bardziej rygorystyczne warunki badania i
                                      bardziej
                                      realistyczne wartości zużycia paliwa/energii i emisji CO2 w porównaniu do stosowanej
                                      to
                                      tej pory metody NEDC. </p>
                                    <p>Dane na podstawie świadectw homologacji typu. Zużycie paliwa/energii, emisja CO2 i
                                      ewentualnie zasięg zostały określone zgodnie z procedurą WLTP. </p>
                                    <p>Wartości mogą się różnić w zależności od wariantu oraz wersji wyposażenia. O
                                      szczegóły
                                      zapytaj Autoryzowanego Dealera Marki Volkswagen lub sprawdź na stronie
                                      www.volkswagen.pl/pl/swiat-volkswagena/ochrona-srodowiska/wltp.html</p>
                                    <p>Montaż akcesoriów w pojeździe może mieć wpływ na poziom zużycia paliwa/energii,
                                      emisję
                                      CO2 lub zasięg oraz może nastąpić najwcześniej po pierwszej rejestracji pojazdu,
                                      wyłącznie na Państwa życzenie.</p>
                                    <p>Rzeczywisty uzyskany czas ładowania może się różnić w zależności od pojemności
                                      akumulatora, korzystania z dodatkowych odbiorników energii, temperatury baterii oraz
                                      poziomu naładowania baterii i jej zużycia, warunków atmosferycznych otoczenia,
                                      sprawności i mocy ładowarki oraz zużycia energii w czasie ładowania przez
                                      systemy.</p>
                                    <p>Zasięg dla samochodów elektrycznych lub pracujących w trybie elektrycznym może się
                                      różnić w zależności od wersji, wyposażenia oraz zamontowanych akcesoriów, stylu
                                      jazdy,
                                      prędkości, korzystania z dodatkowych odbiorników energii, temperatury zewnętrznej,
                                      liczby pasażerów, obciążenia ładunkiem i topografii terenu.</p>
                                    <p>Aby korzystać z usług We Connect, należy posiadać konto użytkownika w serwisie
                                      Volkswagen ID i zalogować się w We Connect za pomocą nazwy użytkownika i hasła.
                                      Ponadto
                                      należy zawrzeć online osobną umowę We Connect lub We Connect Plus z Volkswagen AG. W
                                      przypadku We Connect Plus po przekazaniu pojazdu masz 90 dni na zarejestrowanie go
                                      na
                                      stronie www.myvolkswagen.net lub przez aplikację „Volkswagen We Connect” (dostępna w
                                      App
                                      Store i Google Play Store), aby bezpłatnie korzystać z usług przez cały czas trwania
                                      umowy.</p>
                                    <p>Korzystanie z mobilnych usług online We Connect jest możliwe dzięki zintegrowanemu
                                      połączeniu internetowemu. Związane z tym koszty transmisji danych na terenie Europy
                                      za
                                      wyjątkiem usług „Streaming &amp; Internet” oraz poszczególnych aplikacji In-Car App
                                      ponosi Volkswagen AG w ramach zasięgu sieci. W celu korzystania z usług
                                      „Streaming &amp; Internet”, poszczególnych aplikacji In-Car App oraz hotspotu WLAN
                                      można
                                      nabywać płatne pakiety danych od partnera dostarczającego usługi telekomunikacyjne –
                                      Cubic Telecom – i korzystać z nich w ramach zasięgu sieci wielu krajów na terenie
                                      Europy. Z danych w ramach tych pakietów mogą korzystać wszyscy użytkownicy i
                                      pasażerowie. Informacje dotyczące warunków cen i obsługiwanych krajów są dostępne
                                      pod
                                      adresem https://vw.cubictelecom.com. Alternatywnie można korzystać z radia
                                      internetowego
                                      i strumieniowania mediów za pośrednictwem mobilnego urządzenia końcowego (np.
                                      smartfona)
                                      z funkcją hotspotu mobilnego WLAN. W tym przypadku odpowiednie usługi są dostępne
                                      tylko
                                      na podstawie istniejącej lub zawartej odrębnej umowy z dostawcą usług
                                      telekomunikacyjnych i tylko w ramach zasięgu danej sieci komórkowej. Wymiana danych
                                      za
                                      pośrednictwem Internetu w ramach pakietów danych w zależności od taryfy telefonii
                                      komórkowej, a w szczególności w przypadku korzystania z usługi za granicą, może
                                      wiązać
                                      się z naliczaniem dodatkowych opłat (np. opłat roamingowych).</p>
                                    <p>W celu korzystania z bezpłatnej aplikacji We Connect potrzebujesz smartfona z
                                      odpowiednim systemem operacyjnym iOS lub Android oraz karty SIM z opcją transmisji
                                      danych z istniejącą lub odrębną umową zawartą między dostawcą usług
                                      telekomunikacyjnych
                                      a użytkownikiem. </p>
                                    <p>Dostępność opisanych w pakietach pojedynczych usług We Connect i We Connect Plus
                                      zależnie od kraju może się różnić w zależności od kraju i zależy zarówno od pojazdu,
                                      jak
                                      i wyposażenia. Usługi są dostępne przez określony czas trwania umowy i w tym czasie
                                      mogą
                                      podlegać zmianom merytorycznym lub ich świadczenie może zostać zakończone.
                                      Szczegółowe
                                      informacje można znaleźć na stronie www.connect.volkswagen-we.com i u partnera
                                      Volkswagen. Informacje dotyczące warunków taryfy za usługi komórkowe są dostępne u
                                      dostawców usług telekomunikacyjnych.</p>
                                    <p>
                                    {/* @ts-ignore */}
                                      {this.props.data?.disclaimers?.map((disclaimer, index) => (
                                        <p key={`footer-${index}`} dangerouslySetInnerHTML={{ __html: disclaimer?.content }} />
                                      ))}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
    )
  }
}
