// @ts-nocheck
import * as React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { ISection, IDetailsSectionType } from '../../types';
import { ICalculatorResults } from '../../contexts/types';
import { PdfTable } from './PdfTable';

export type IPdfExteriorsAndInteriorsProps = {
  type: IDetailsSectionType;
  children?: React.ReactNode;
  data: ISection;
  calculationResults: ICalculatorResults;
};

export const PdfExteriorsAndInteriors = (props: IPdfExteriorsAndInteriorsProps) => {
  const lines = new Set<string>();
  props.data.groups?.forEach((group) => {
    group.items.forEach((item) => {
      if (item.availability) {
        Object.keys(item.availability).forEach((line) => lines.add(line));
      }
    });
  });
  const equipmentLines = Array.from(lines);
  const equipmentLinesRowWidth = 20 + equipmentLines.length * 10;
  const widthCell1 = `${100 - (equipmentLinesRowWidth <= 70 ? equipmentLinesRowWidth : 70)}%`;

  return (
    <View style={{ borderTop: '1.5px solid #001E50', marginHorizontal: 24 }}>
      <View style={styles.preTable}>
        {equipmentLines.map((equipment: string, index: number) => (
          <View
            key={`top-${equipment}`}
            style={{
              ...styles.preTableCell,
              marginLeft: !index ? widthCell1 : 0,
              borderLeft: `2px solid ${index === 0 ? '#001E50' : '#DFE4E8'}`,
              width: '100%'
            }}
          >
            <Text style={{ fontFamily: 'VWHead', fontSize: '12px' }}>{equipment}</Text>
          </View>
        ))}
      </View>

      {props?.data?.groups?.map((group, indexGroup) => (
        <View key={group?.name}>
          <View style={{ margin: '4px 0 4px 8px' }}>
            <Text style={{ fontFamily: 'VWHead', fontSize: '12px' }}>{group?.name}</Text>
          </View>
          <PdfTable
            type={props.type}
            section={props.data}
            group={group}
            calculationResults={props.calculationResults}
            equipmentLines={equipmentLines}
          />
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    marginHorizontal: 24
  },
  tableTitle: {
    fontSize: 12,
    marginRight: 9,
    display: 'inline-block'
  },
  table: {
    borderTop: '1.5px solid #001E50',
    borderBottom: '1.5px solid #001E50',
    display: 'flex'
  },
  image: {
    borderRadius: '30px',
    width: 44,
    height: 44,
    margin: '8px 6px 16px 8px'
  },
  cell: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 9,
    width: 183,
    fontWeight: '400',
    color: '#001E50',
    height: 66.5
  },
  cellText: {
    display: 'flex',
    width: 136,
    marginTop: 8
  },
  preTable: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8
  },
  preTableCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 8px',
    borderBottom: '2px solid #001E50'
  }
});
