import { useState } from 'react';
import { List, ListItemButton, ListItemText, Collapse, Theme, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

import { ICalculatorProduct } from '../../../types';

export type IProductListProps = {
  selectedProductName: string;
  calculatorProducts: ICalculatorProduct[] | undefined;
  onSelectProduct: (product: ICalculatorProduct) => void;
};

export const ProductList = (props: IProductListProps) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [typeOfCustomer, setTypeOfCustomer] = useState<{ id: number; value: 'Firma' | 'Indywidualny' }>({
    id: 0,
    value: 'Firma'
  });

  const selectedProductHandler = (product: ICalculatorProduct) => {
    props.onSelectProduct(product);
    setOpen(false);
  };

  const handleChange = (event: unknown, index: number) => {
    setTypeOfCustomer({ id: index, value: index ? 'Indywidualny' : 'Firma' });
  };

  const a11yProps = (index: number) => {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`
    };
  };

  const getFilteredProducts = (products: ICalculatorProduct[] | undefined): ICalculatorProduct[] | undefined => {
    const searchKey =
      typeOfCustomer.value === 'Firma' ? 'leasing' : typeOfCustomer.value === 'Indywidualny' ? 'credit' : undefined;
    const filteredProducts = searchKey && products?.filter((product) => !product.product_slug.search(searchKey));

    return filteredProducts;
  };

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav" aria-labelledby="nested-list-subheader">
      <ListItemButton onClick={() => setOpen(!open)} sx={{ px: 0.5 }}>
        <ListItemText>
          <Typography color="#184185" fontWeight={700}>
            {props.selectedProductName ? props.selectedProductName : 'Nie wybrano produktu'}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Tabs
          value={typeOfCustomer.id}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="product category tabs"
          variant="fullWidth"
          className={classes.tabs}
        >
          {['Firma', 'Indywidualny'].map((tabName, index) => (
            <Tab key={tabName} label={<span>{tabName}</span>} component="a" {...a11yProps(index)} />
          ))}
        </Tabs>
        <Typography marginY={2} fontWeight={700}>
          Wybierz produkt z listy
        </Typography>
        {getFilteredProducts(props.calculatorProducts)?.map((product, index) => (
          <List
            key={product.product_slug}
            onClick={() => selectedProductHandler(product)}
            component="div"
            disablePadding
          >
            <ListItemButton
              sx={{
                backgroundColor: index % 2 ? 'white' : '#66749122',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start'
              }}
            >
              <ListItemText>
                <Typography color="#184185" fontWeight={700}>
                  {product.name}
                </Typography>
              </ListItemText>

              <ListItemText>
                <Typography fontSize={12}>{product.product_card_text}</Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        ))}
      </Collapse>
    </List>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    color: '#001E50',
    backgroundColor: '#e4e4e4',
    borderRadius: '50px',
    '& .MuiTabs-indicator': {
      borderRadius: '50px',
      backgroundColor: '#001E50',
      height: '100%',
      zIndex: 2
    },
    '& .MuiTab-root.Mui-selected': {
      color: '#ddd'
    },
    '& .MuiTab-root': {
      fontSize: 14,
      fontWeight: 'bold',
      borderRadius: '50px',
      textTransform: 'none',
      zIndex: 3
    }
  }
}));
