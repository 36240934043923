import { Fragment } from 'react';
import { Grid, Theme, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Gallery } from '../../UI';
import { useWindowDimensions } from '../../../utils/hooks/useWindowDimensions';
import { ISection } from '../../../types';

export type IDimensionsProps = {
  data: ISection;
};

export const Dimensions = (props: IDimensionsProps) => {
  const classes = useStyles();
  const { isOnMobileBreakpoint } = useWindowDimensions();

  if (!props.data) {
    return null;
  }

  const { length, width, height, trunk, trunkHybrid } = props.data;

  const tableHeading = ['Długość', 'Szerokość', 'Wysokość', 'Pojemność bagażnika'];

  const lengthText = length ? `${length} mm` : 'brak';
  const widthText = width ? `${width} mm` : 'brak';
  const heightText = height ? `${height} mm` : 'brak';
  const trunkText = trunk ? `Ogólna: ${trunk} L` : 'brak';
  const trunkHybridText = trunkHybrid ? `Hybryda: ${trunkHybrid} L` : 'Hybryda: brak';

  const tableBody = [lengthText, widthText, heightText, trunkText, trunkHybridText];

  return (
    <div>
      {isOnMobileBreakpoint ? (
        <Grid container>
          {tableHeading.map((heading, index) => (
            <Fragment key={heading}>
              <Grid item xs={12} className={classes.cell1} style={{ color: 'black', fontWeight: 700 }}>
                {heading}
              </Grid>
              <Grid item xs={12} className={classes.cell2}>
                {tableBody[index]}
              </Grid>
              {index === tableHeading.length - 1 ? (
                <Grid item xs={12} className={classes.cell2}>
                  {tableBody[index + 1]}
                </Grid>
              ) : null}
            </Fragment>
          ))}
        </Grid>
      ) : (
        <>
          <Grid container className={classes.tableHead}>
            {tableHeading.map((heading, index) => (
              <Grid
                key={heading}
                item
                xs={index < tableHeading.length - 1 ? 2.4 : 4.8}
                className={classes.cell3}
                style={{ color: 'black', fontWeight: 700 }}
              >
                {heading}
              </Grid>
            ))}
          </Grid>
          <Grid container className={classes.tableBody}>
            {tableBody.map((heading, index) => (
              <Grid key={heading} item xs={2.4} className={classes.cell3}>
                {heading}
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <Box marginTop={2}>
        <Gallery type={props.data.type} images={props.data.images} />
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  tableHead: {
    marginTop: '40px',
    borderBottom: '2px solid #001E50',
    '& :last-child': {
      borderRight: '2px solid #ffffff'
    }
  },
  tableBody: {
    borderBottom: '2px solid #DFE4E8',
    '& :last-child': {
      borderRight: '2px solid #ffffff'
    }
  },

  cell1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px 16px',
    color: '#001E50',
    borderBottom: '2px solid #001E50'
  },
  cell2: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 16px',
    color: '#001E50',
    borderBottom: '2px solid #DFE4E8'
  },
  cell3: {
    display: 'flex',
    justifyContent: 'center',
    borderRight: '2px solid #DFE4E8',
    padding: '20px 16px',
    color: '#001E50'
  }
}));
