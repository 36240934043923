// @ts-nocheck
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import * as React from 'react';

import { IGroup, ISection, IDetailsSectionType, IItem } from '../../../types';
import { ICalculatorResults } from '../../../contexts/types';

export type IPdfTableProps = {
  type: IDetailsSectionType;
  children?: React.ReactNode;
  section: ISection;
  group: IGroup;
  calculationResults: ICalculatorResults;
  equipmentLines?: string[];
};

export const PdfTable = (props: IPdfTableProps) => {
  const { calculationResults } = props;

  const equipmentLines = props?.section?.equipmentLines || props.equipmentLines || [];
  const equipmentLinesRowWidth = 20 + equipmentLines.length * 10;
  const widthAllCell2 = equipmentLinesRowWidth <= 70 ? equipmentLinesRowWidth : 70;
  const widthCell1 = `${100 - widthAllCell2}%`;
  const widthCell2 = `${Math.floor(widthAllCell2 / equipmentLines.length)}%`;

  const isCalculatedEngineResults = (baseCode?: string): boolean =>
    !!calculationResults.results.find((result) => result.engineBaseCode === baseCode);

  const getCalculatedPriceText = (price?: number, baseCode?: string) => {
    if (price) {
      if (baseCode) {
        const engineResults = calculationResults.results.find((result) => result.engineBaseCode === baseCode);

        if (engineResults) {
          return `${Math.ceil(engineResults.installment).toLocaleString('sk-SK')} PLN netto/mc`;
        } else {
          return `${price.toLocaleString('sk-SK')} PLN`;
        }
      } else {
        if (calculationResults.percentage === 1) {
          return `${price.toLocaleString('sk-SK')} PLN`;
        } else {
          const calculatedPrice = Math.ceil(calculationResults.percentage * price).toLocaleString('sk-SK');
          return `${calculatedPrice} PLN netto/mc`;
        }
      }
    } else return '';
  };

  const getPriceBeforeDiscountDisclaimer = (item: IItem) => {
    return item?.priceBeforeDiscountDisclaimer ? (
      <Text style={styles.disclaimerOmnibus}>{item.priceBeforeDiscountDisclaimer}</Text>
    ) : (
      ''
    );
  };

  const getDesktopPdfTableCells = (group: IGroup, type: IDetailsSectionType, itemsCode?: any) => {
    let keyExtractor: 'engineFullName' | 'code';

    if (type === 'models') {
      keyExtractor = 'engineFullName';
    } else if (type === 'features' || type === 'exteriors' || type === 'interiors') {
      keyExtractor = 'code';
    } else {
      return;
    }

    const newPdfTable = group.items.map((item) => {
      const { [keyExtractor]: itemProp } = item;
      return itemProp as string;
    });

    const filteredNewPdfTable = newPdfTable?.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    if (keyExtractor === 'engineFullName' && props?.section?.equipmentLines && filteredNewPdfTable) {
      const emptyArray = [];
      for (const engineFullName of filteredNewPdfTable) {
        const emptyRow = [];
        emptyRow.push(
          <View style={{ ...styles.cell1, width: widthCell1 }}>
            <Text style={{ fontFamily: 'VWHead', fontSize: '10px' }}>{engineFullName}</Text>
          </View>
        );
        for (const equipmentLine of props?.section?.equipmentLines) {
          const item = group?.items.find(
            (item) => item?.equipmentLine === equipmentLine && item?.engineFullName === engineFullName
          );

          if (item && item.price) {
            const { price } = item;
            const borderRight = emptyRow.length !== props?.section?.equipmentLines.length ? '2px solid #DFE4E8' : '';

            emptyRow.push(
              <View
                style={{ ...styles.cell2, width: widthCell2, borderRight }}
                key={`${engineFullName}-${equipmentLine}-${props.type}`}
              >
                <Text style={styles.priceBeforeDiscount}>
                  {item.priceBeforeDiscount ? getCalculatedPriceText(item.priceBeforeDiscount, item.baseCode) : ''}
                </Text>
                <Text
                  style={{
                    fontFamily: isCalculatedEngineResults(item.baseCode) ? 'VWHead' : 'VWHead',
                    fontSize: '8px'
                  }}
                >
                  {getCalculatedPriceText(price, item.baseCode)}
                </Text>
                {getPriceBeforeDiscountDisclaimer(item)}
              </View>
            );
          } else {
            emptyRow.push(
              <View style={{ ...styles.cell2, width: widthCell2 }} key={`${Math.random()}`}>
                <Text>-</Text>
              </View>
            );
          }
        }

        emptyArray.push(<View style={styles.td}>{emptyRow}</View>);
      }

      return emptyArray;
    } else if (keyExtractor === 'code' && props?.section?.equipmentLines && filteredNewPdfTable) {
      const emptyArray = [];
      for (const code of filteredNewPdfTable) {
        const emptyRow = [];
        emptyRow.push(
          <View style={{ ...styles.cell1, width: widthCell1 }}>
            <Text>{itemsCode[code]?.name}</Text>
          </View>
        );
        for (const equipmentLine of props?.section?.equipmentLines) {
          const item = group?.items.find((item) => item?.equipmentLine === equipmentLine && item?.code === code);

          emptyRow.push(
            <View style={{ ...styles.cell2, width: widthCell2 }} key={`${code}-${equipmentLine}-${props.type}`}>
              {item?.isStandard ? (
                <Image source={`/fonts/VW_Pdf_Icons/tick.png`} style={styles.icon} />
              ) : (
                <>
                  <Text style={styles.priceBeforeDiscount}>
                    {item?.priceBeforeDiscount ? getCalculatedPriceText(item.priceBeforeDiscount, item.baseCode) : ''}
                  </Text>
                  <Text>{item?.price ? getCalculatedPriceText(item?.price) : '-'}</Text>
                  {item && getPriceBeforeDiscountDisclaimer(item)}
                </>
              )}
            </View>
          );
        }

        emptyArray.push(<View style={styles.td}>{emptyRow}</View>);
      }

      return emptyArray;
    } else if (keyExtractor === 'code' && (type === 'exteriors' || type === 'interiors') && filteredNewPdfTable) {
      const emptyArray = [];

      for (const interiorCode of filteredNewPdfTable) {
        const emptyRow = [];
        const item: IItem | undefined = props.section?.groups
          ?.flatMap((group) => group.items)
          ?.find((item: IItem) => item.code === interiorCode);

        if (item) {
          emptyRow.push(
            <View style={{ ...styles.cell1, width: widthCell1 }}>
              <Image src={item.imageUrl} style={styles.image} />
              <Text style={{ fontFamily: 'VWHead', fontSize: '10px' }}>{item.name}</Text>
            </View>
          );
        }
        for (const equipmentLine of props?.equipmentLines) {
          const item = group?.items.find(
            (item) => item?.availability?.[equipmentLine] === true && item.code === interiorCode
          );

          const borderRight = emptyRow.length !== props?.equipmentLines.length ? '2px solid #DFE4E8' : '';

          emptyRow.push(
            <View
              style={{ ...styles.cell2, width: widthCell2, borderRight }}
              key={`${interiorCode}-${equipmentLine}-${props.type}`}
            >
              <Text>
                {item && item.price !== undefined
                  ? item.price > 0
                    ? getCalculatedPriceText(item.price)
                    : 'Bez dopłaty'
                  : '-'}
              </Text>
            </View>
          );
        }

        emptyArray.push(<View style={styles.td}>{emptyRow}</View>);
      }

      return emptyArray;
    }
  };

  return (
    <View container style={styles.table}>
      {getDesktopPdfTableCells(props.group, props.type, props.section.items)?.map((desktopPdfTableCell, index) => (
        <View key={`dasktop-grid-${props.type}-${index}`} style={{ borderTop: index ? '2px solid #DFE4E8' : '' }}>
          {desktopPdfTableCell}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  table: {
    borderTop: '2px solid #001E50',
    borderBottom: '2px solid #001E50'
  },
  td: {
    display: 'flex',
    flexDirection: 'row'
  },
  cell1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    fontSize: 10,
    color: '#001E50',
    borderRight: '2px solid #001E50',
    padding: '6px 8px'
  },
  cell2: {
    display: 'flex',
    flexGrow: 1,
    fontSize: 8,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#001E50'
  },
  icon: {
    height: 10,
    width: 10
  },
  priceBeforeDiscount: {
    color: '#6A767D',
    textDecoration: 'line-through'
  },
  disclaimerOmnibus: {
    color: '#6A767D',
    lineHeight: 1.1,
    margin: 3,
    textAlign: 'center'
  },
  image: {
    borderRadius: '30px',
    width: 22,
    height: 22,
    margin: '8px 6px 16px 8px'
  }
});
