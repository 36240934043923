import * as React from 'react';
import { useState } from 'react';

import { Grid, Theme, Box, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useWindowDimensions } from '../../../utils/hooks/useWindowDimensions';
import { useCalculatorContext } from '../../../hooks/useCalculatorContext';
import { ISection, IDetailsSectionType, IGroup, IItem } from '../../../types';

function a11yProps(index: any) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`
  };
}

export type IWheelsProps = {
  type: IDetailsSectionType;
  children?: React.ReactNode;
  data: ISection;
};

export const Wheels = (props: IWheelsProps) => {
  const classes = useStyles();
  const { calculationResults } = useCalculatorContext();

  const [selectedMobileCol, setSelectedMobileCol] = useState(0);
  const { isOnMobileBreakpoint } = useWindowDimensions();

  const handleChangeIndex = (index: number) => {
    setSelectedMobileCol(index);
  };

  const getCalculatedPriceText = (price?: number) => {
    if (price !== null && price !== undefined) {
      if (calculationResults.percentage === 1) {
        return `${Math.ceil(price as number).toLocaleString('sk-SK')} PLN`;
      } else {
        const calculatedPrice = Math.ceil(calculationResults.percentage * price).toLocaleString('sk-SK');
        return `${calculatedPrice} PLN netto/mc`;
      }
    } else return '';
  };

  const getPriceBeforeDiscountDisclaimer = (item: IItem) => {
    return item.priceBeforeDiscountDisclaimer ? (
      <div className={classes.disclaimerOmnibus}>{item.priceBeforeDiscountDisclaimer}</div>
    ) : (
      ''
    );
  };

  if (!props.data) {
    return null;
  }

  const groupsLength = props.data.groups ? props.data.groups.length : 3;
  const itemsLength = props.data.groups ? props.data.groups.map((group) => group.items.length) : [3];
  const emptyGrid = [...Array(Math.max(...itemsLength))].map((e) => Array(groupsLength).fill(''));

  const convertedGrid = emptyGrid.map((emptyCol, indexCol) =>
    emptyCol.map((emptyRow, indexRow) =>
      props.data.groups?.at(indexRow)
        ? props.data.groups.at(indexRow)?.items?.at(indexCol)
          ? props.data.groups.at(indexRow)?.items?.at(indexCol)
          : null
        : null
    )
  );

  return (
    <div>
      {isOnMobileBreakpoint ? (
        <Tabs
          value={selectedMobileCol}
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          aria-label="action tabs example"
          scrollButtons
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {props?.data?.groups?.map((group: IGroup, index: number) => (
            <Tab
              key={`wheels-${group.name}-${index}`}
              onClick={() => handleChangeIndex(index)}
              label={group.name}
              className={classes.tab}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      ) : (
        <Grid container className={classes.preTable}>
          {props?.data?.groups?.map((group: IGroup, index: number) => (
            <Grid
              item
              xs={12 / groupsLength}
              className={classes.preTableCell}
              key={`top-wheels-${group.name}`}
              style={{
                borderRight: `2px solid ${
                  props?.data?.groups && props.data.groups.length - 1 === index ? '#ffffff' : '#DFE4E8'
                }`
              }}
            >
              <div>{group.name}</div>
            </Grid>
          ))}
        </Grid>
      )}

      <div className={classes.table}>
        {isOnMobileBreakpoint ? (
          props?.data?.groups?.map((group, indexCol) =>
            selectedMobileCol === indexCol ? (
              <div key={`wheel-group-${indexCol}`}>
                <Grid container>
                  {group.items.map((item, indexRow) => (
                    <Grid
                      key={`wheel-item-${indexRow}`}
                      item
                      xs={isOnMobileBreakpoint ? 12 : groupsLength}
                      className={classes.cell}
                    >
                      {item ? (
                        <>
                          <img
                            alt={item.name}
                            src={item.imageUrl}
                            width={60}
                            height={60}
                            style={{ borderRadius: '30px' }}
                          />
                          <Box paddingLeft={2}>
                            <div>{item.name}</div>
                            <div className={classes.priceBeforeDiscount}>
                              {item?.priceBeforeDiscount ? getCalculatedPriceText(item?.priceBeforeDiscount) : ''}
                            </div>
                            <div>{getCalculatedPriceText(item.price)}</div>
                            {getPriceBeforeDiscountDisclaimer(item)}
                          </Box>
                        </>
                      ) : null}
                    </Grid>
                  ))}
                </Grid>
              </div>
            ) : null
          )
        ) : (
          <>
            {convertedGrid?.map((group, indexCol) => (
              <div key={`wheel-group-${indexCol}`}>
                <Grid container>
                  {group.map((item, indexRow) => (
                    <Grid
                      key={`wheel-item-${indexRow}`}
                      item
                      xs={12 / groupsLength}
                      className={classes.cell}
                      style={{ borderRight: `2px solid ${group.length - 1 === indexRow ? '#ffffff' : '#DFE4E8'}` }}
                    >
                      {item ? (
                        <>
                          <img
                            alt={item.name}
                            src={item.imageUrl}
                            width={60}
                            height={60}
                            style={{ borderRadius: '30px' }}
                          />
                          <Box className={classes.box}>
                            <div>
                              <b>{item.name}</b>
                            </div>
                            <div>{item?.info}</div>
                            <div>{item?.isStandard ? 'Wyposażenie standardowe' : null}</div>
                            <div className={classes.priceBeforeDiscount}>
                              {item?.priceBeforeDiscount ? getCalculatedPriceText(item?.priceBeforeDiscount) : ''}
                            </div>
                            <div>{getCalculatedPriceText(item.price)}</div>
                            {getPriceBeforeDiscountDisclaimer(item)}
                          </Box>
                        </>
                      ) : null}
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  preTable: {
    marginTop: '40px'
  },
  preTableCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
    fontWeight: 700
  },
  tabs: {
    margin: '0 40px 20px',
    height: 48,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  tab: {
    textTransform: 'none',
    height: 48
  },
  header: {
    textAlign: 'center',
    backgroundColor: theme.palette.grey[200]
  },
  table: {
    borderTop: '2px solid #001E50'
  },
  cell: {
    display: 'flex',
    fontSize: 14,
    color: '#001E50',
    padding: '16px 0px',
    borderBottom: '2px solid #DFE4E8',
    height: 'auto'
  },
  box: {
    paddingRight: '12px',
    paddingLeft: '3px',
    wordBreak: 'break-all'
  },
  priceBeforeDiscount: {
    color: '#6A767D',
    textDecorationLine: 'line-through'
  },
  disclaimerOmnibus: {
    color: '#6A767D',
    lineHeight: 1.1,
    maxWidth: '75%',
    margin: '5px 0'
  }
}));
