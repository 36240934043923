import { useContext } from 'react';

import { CalculatorContext } from '../contexts/CalculatorContext';

export const useCalculatorContext = () => {
  const context = useContext(CalculatorContext);

  if (!context) throw new Error('Calculator context must be use inside CalculatorProvider');

  return context;
};
