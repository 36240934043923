// @ts-nocheck
import * as React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { ISection, IDetailsSectionType, IGroup } from '../../types';
import { ICalculatorResults } from '../../contexts/types';

export type IPdfWheelsProps = {
  type: IDetailsSectionType;
  children?: React.ReactNode;
  data: ISection;
  calculationResults: ICalculatorResults;
};

export const PdfWheels = (props: IPdfWheelsProps) => {
  const { calculationResults } = props;

  const getCalculatedPriceText = (price?: number) => {
    if (price) {
      if (calculationResults.percentage === 1) {
        return `${Math.ceil(price).toLocaleString('sk-SK')} PLN`;
      } else {
        const calculatedPrice = Math.ceil(calculationResults.percentage * price).toLocaleString('sk-SK');
        return `${calculatedPrice} PLN netto/mc`;
      }
    } else return '';
  };

  const getPriceBeforeDiscountDisclaimer = (item: IItem) => {
    return item?.priceBeforeDiscountDisclaimer ? (
      <Text style={styles.disclaimerOmnibus}>{item.priceBeforeDiscountDisclaimer}</Text>
    ) : (
      ''
    );
  };

  if (!props.data) {
    return null;
  }

  const groupsLength = props.data.groups ? props.data.groups.length : 3;
  const itemsLength = props.data.groups ? props.data.groups.map((group) => group.items.length) : [3];
  const emptyGrid = [...Array(Math.max(...itemsLength))].map((e) => Array(groupsLength).fill(''));
  const cellWidth = Math.floor(547 / groupsLength);

  const convertedGrid = emptyGrid.map((emptyCol, indexCol) =>
    emptyCol.map((emptyRow, indexRow) =>
      props.data.groups?.at(indexRow)
        ? props.data.groups.at(indexRow)?.items?.at(indexCol)
          ? props.data.groups.at(indexRow)?.items?.at(indexCol)
          : null
        : null
    )
  );

  const calculateCellStyle = (indexRow: number, indexCol: number) => {
    const X = cellWidth * indexRow;
    const Y = 105 * indexCol;

    const transform = `translate(${X}px,${Y}px)`;
    const borderRight = indexRow !== groupsLength - 1 ? '1.5px solid #DFE4E8' : '';

    return { transform, borderRight };
  };

  return (
    <View style={styles.section}>
      <View style={styles.preTable}>
        {props?.data?.groups?.map((group: IGroup, index: number) => (
          <View
            style={{ ...styles.preTableCell, borderLeft: `2px solid ${index === 0 ? '#ffffff' : '#DFE4E8'}` }}
            key={`top-PdfWheels-${group.name}`}
          >
            <Text>{group.name}</Text>
          </View>
        ))}
      </View>

      <View style={styles.table}>
        {convertedGrid?.map((group, indexCol) => (
          <View key={`wheel-group-${indexCol}`}>
            {group.map((item, indexRow) => (
              <View
                key={`wheel-item-${indexRow}`}
                style={{ ...styles.cell, width: cellWidth, ...calculateCellStyle(indexRow, indexCol) }}
              >
                {item && <Image src={item.imageUrl} style={styles.image} />}
                {item && (
                  <View style={{ marginTop: 8, width: cellWidth - 50 }}>
                    <Text>
                      <Text>{item.name}</Text>
                    </Text>
                    <Text style={{ marginTop: 1.5 }}>{item?.info}</Text>
                    <Text style={{ marginTop: 1.5 }}>{item?.isStandard ? 'Wyposażenie standardowe' : null}</Text>
                    <Text style={styles.priceBeforeDiscount}>
                      {item.priceBeforeDiscount ? getCalculatedPriceText(item.priceBeforeDiscount, item.baseCode) : ''}
                    </Text>
                    <Text style={{ marginTop: 1.5 }}>{getCalculatedPriceText(item.price)}</Text>
                    {item && getPriceBeforeDiscountDisclaimer(item)}
                  </View>
                )}
              </View>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    marginHorizontal: 24
  },
  preTable: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '2px solid #001E50',
    fontSize: '12px'
  },
  preTableCell: {
    display: 'flex',
    flexGrow: 1,
    padding: '5px 8px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  table: {},
  cell: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 9,
    fontWeight: '400',
    color: '#001E50',
    height: 105,
    borderBottom: '1.5px solid #DFE4E8'
  },
  image: {
    borderRadius: '30px',
    width: 44,
    height: 44,
    margin: '8px 6px 16px 8px'
  },
  priceBeforeDiscount: {
    color: '#6A767D',
    textDecoration: 'line-through',
    marginTop: 1.5
  },
  disclaimerOmnibus: {
    color: '#6A767D',
    lineHeight: 1,
    marginTop: 1.5,
    fontSize: 6
  }
});
