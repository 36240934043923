// @ts-nocheck
import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import { IPdfIcon, getPdfPageHeaders } from '../pdfPageHeaders';
import { IData } from 'src/types';

export type IPdfFooterProps = {
  icon: IPdfIcon;
  children?: React.ReactNode;
  appData: IData;
};

export const PdfFooter = (props: IPdfFooterProps) => {
  const pdfPageHeaders = getPdfPageHeaders(props.appData);

  return (
    <View style={styles.footer} fixed>
      <View style={{ alignSelf: 'center' }}>
        <Text
          style={{
            fontSize: 6,
            maxWidth: '418px',
            alignSelf: 'center',
            justifyContent: 'center'
          }}
        >
          Oglądając ten cennik w dowolnej przeglądarce PDF na Twoim urządzeniumożesz szybko przełączaćsię pomiędzy
          poszczególnymi jego częściami klikając na ikony, które znajdują się na wszystkich stronach cennika w tym samym
          miejscu
        </Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 12 }}>
        <View style={{ fontSize: 8 }}>
          <Text render={({ pageNumber, totalPages }) => `Strona ${pageNumber} z ${totalPages}`} fixed />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {pdfPageHeaders
            .filter((header) => !!header.icon)
            .map((pageHeader) => (
              <Image
                key={pageHeader?.icon}
                source={`/fonts/VW_Pdf_Icons/${pageHeader.icon}${pageHeader.icon === props.icon ? '-light' : ''}.png`}
                style={styles.footerIcon}
              />
            ))}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    height: '76px',
    width: '100%',
    backgroundColor: '#F3F5F6',
    padding: '8px 24px',
    color: '#001E50',
    position: 'absolute',
    bottom: 0
  },
  footerIcon: {
    height: 16,
    width: 16,
    marginLeft: 16
  }
});
