import { Text, TokenTextColor } from '@volkswagen-onehub/components-core';
import { Typography, Theme, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ISection } from '../../types';
import { useWindowDimensions } from '../../utils/hooks/useWindowDimensions';
import './style.css';

export type IBoxSectionProps = {
  key: number;
  type: 'box';
  data?: ISection;
};

export const BoxSection = (props: IBoxSectionProps) => {
  const classes = useStyles();
  const { isOnMobileBreakpoint } = useWindowDimensions();

  if (!props.data) {
    return null;
  }

  return (
    <div className={classes.container} key={props.key}>
      <Box
        gridColumn={isOnMobileBreakpoint ? 1 : props.data.textAlign === 'left' ? 1 : 2}
        gridRow={1}
        alignSelf="center"
        sx={{ margin: { xs: '0 32px 32px', md: '0 10%' } }}
      >
        <Typography variant="h3" className={classes.h3} align="left">
          <Text color={TokenTextColor.inherit}>
            <div dangerouslySetInnerHTML={{ __html: props.data.title }} />
          </Text>
        </Typography>
        <Typography variant="h4" className={classes.h4} align="left">
          <Text color={TokenTextColor.inherit}>
            <Box marginTop={2} sx={{ fontSize: 16 }}>
              <div className="box-description" dangerouslySetInnerHTML={{ __html: props.data.description }} />
            </Box>
          </Text>
        </Typography>
      </Box>
      <Box
        gridColumn={isOnMobileBreakpoint ? 1 : props.data.textAlign === 'left' ? 2 : 1}
        gridRow={isOnMobileBreakpoint ? 2 : 1}
      >
        <Box
          component="img"
          sx={{
            width: { xs: '100%', md: '80%' },
            margin: { md: '3vw 10% 0' }
          }}
          src={props.data.imageUrl}
          alt="box"
        />
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 50,
    display: 'grid',
    gridTemplateColumns: '100%',
    color: '#001E50',
    [theme.breakpoints.up('sm')]: {
      margin: '0px 15vw 0 3vw',
      gridTemplateColumns: '1fr 1fr'
    }
  },

  h3: {
    fontSize: 20,
    color: 'black',
    [theme.breakpoints.up('sm')]: {
      fontSize: 30
    }
  },
  h4: {
    fontWeight: 400,
    marginTop: 32,
    [theme.breakpoints.up('sm')]: {}
  }
}));
