// @ts-nocheck
import React from 'react';
import { Page, StyleSheet } from '@react-pdf/renderer';

import { IData } from '../../types';
import { ICalculatorResults } from '../../contexts/types';
import { PdfHeader, PdfFooter } from './index';
import type { IPdfPageHeaderProps } from '../pdfPageHeaders';

export type IPageWrapperProps = {
  pdf: IPdfPageHeaderProps;
  appData: IData;
  calculationResults: ICalculatorResults;
  children?: React.ReactNode;
};

export const PageWrapper = (props: IPdfPageHeaderProps) => {
  return (
    <Page size="A4" style={styles.page} wrap>
      <PdfHeader {...props} />
      {props.children}
      <PdfFooter {...props} />
    </Page>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: 'VWHead',
    paddingTop: '103px',
    paddingBottom: '76px'
  }
});
