import { useQuery } from 'react-query';
import { ICalculatorProduct } from '../types';
import fetchCalculatorProducts from '../queries/fetchCalculatorProducts';

export const useCalculatorProducts = () => {
  const {
    data: dataCalculatorProducts,
    isLoading: isLoadingCalculatorProducts,
    isError: isErrorCalculatorProducts
  } = useQuery<ICalculatorProduct[]>(['calculatorProducts'], () => fetchCalculatorProducts());

  const filteredDataCalculatorProducts = dataCalculatorProducts?.filter((product) => product.state === 'active');

  return {
    dataCalculatorProducts: filteredDataCalculatorProducts,
    isLoadingCalculatorProducts,
    isErrorCalculatorProducts
  };
};
