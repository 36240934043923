import { useEffect, useMemo, useState } from 'react';
import { useCreateAndRemoveEventListener } from './useCreateAndRemoveEventListener';

export const useWindowDimensions = (handleResizeSideEffect?: () => void) => {
  const [innerWidth, setInnerWidth] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);
  const [pageYOffset, setPageYOffset] = useState(0);
  const [pageXOffset, setPageXOffset] = useState(0);

  const S_BREAKPOINT = 600;
  const isOnMobileBreakpoint = useMemo(() => innerWidth < S_BREAKPOINT, [innerWidth]);

  const handleResize = () => {
    setInnerWidth(window.innerWidth);
    setInnerHeight(window.innerHeight);
    setPageXOffset(window.pageXOffset);
    setPageYOffset(window.pageYOffset);

    if (handleResizeSideEffect) {
      handleResizeSideEffect();
    }
  };

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCreateAndRemoveEventListener('resize', handleResize);

  return {
    innerWidth,
    innerHeight,
    pageYOffset,
    pageXOffset,
    isOnMobileBreakpoint
  };
};
