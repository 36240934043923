import { Box, styled, Dialog, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useWindowDimensions } from '../../utils/hooks/useWindowDimensions';
import './style.css';

export type IConfirmingModalProps = {
  isError: boolean;
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

export const ConfirmingModal = (props: IConfirmingModalProps) => {
  const { isOnMobileBreakpoint } = useWindowDimensions();

  return (
    <ConfirmingDialog open={props.open}>
      <ActionCloseIcon onClose={props.onClose} />
      {props.isError ? (
        <Box>Uwaga, Uwaga Error !!!</Box>
      ) : (
        <Box>
          <Box display="flex" justifyContent="center">
            <MainIcon />
          </Box>
          <Box marginTop={2}>
            <Typography fontWeight="bold" color="white" sx={{ fontSize: { xs: 22, md: 30 } }}>
              Jesteś o krok bliżej nowego Volkswagena!
            </Typography>
          </Box>
          <Box sx={{ marginTop: { xs: 2, md: 1 } }}>
            <Typography color="#ffffff88">
              Skorzystanie z formularza kontaktowego jest już za Tobą. Co dalej?
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection={isOnMobileBreakpoint ? 'column' : 'row'}
            margin={isOnMobileBreakpoint ? 0 : '20px 0 35px 35px'}
            justifyContent="center"
          >
            {[
              {
                icon: 'vw-pl-phone',
                text: ['Zadzwonimy', 'potwierdzić dane']
              },
              {
                icon: 'vw-pl-contact-dealer',
                text: ['Przekarzemy Twój', 'kontakt do Dealera']
              },
              {
                icon: 'vw-pl-car-documents',
                text: ['Dealer omówi z Tobą', 'wszystkie szczegóły']
              }
            ].map((cmp, index, arr) => (
              <Box
                key={cmp.text[0]}
                component="span"
                display="flex"
                flexDirection="row"
                position="relative"
                paddingTop={5}
                width={index < arr.length ? 220 : 180}
              >
                <Box
                  component="span"
                  sx={{
                    padding: '1.5px 1.5px',
                    fontSize: 12,
                    position: 'absolute',
                    color: 'rgba(0,34,84,1)',
                    backgroundColor: 'white',
                    width: 20,
                    height: 20,
                    lineHeight: '18px',
                    transform: 'translate(-25px,-25px)',
                    borderRadius: '50px'
                  }}
                >
                  {index + 1}
                </Box>
                <span className={`${cmp.icon} icon-confirm-form`} />
                <Box component="span" display="flex" flexDirection="column" alignItems="start">
                  <Box component="span" color="#ffffffd1" fontSize={14} lineHeight="18px">
                    {cmp.text[0]}
                  </Box>
                  <Box component="span" color="#ffffffd1" fontSize={14} lineHeight="18px">
                    {cmp.text[1]}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          {!isOnMobileBreakpoint && (
            <Box sx={{ marginTop: { xs: 2, md: 1 } }}>
              <Typography color="#ffffff88" fontSize={14} lineHeight="18px">
                Zapoznaj się z naszym poradnikiem procesu zakupu i rejestracji nowego Volkswagena.
              </Typography>
              <Typography color="#ffffff88" fontSize={14} lineHeight="18px">
                Kupno nowego pojazdu jest prostsze niż Ci się wydaje.
              </Typography>
            </Box>
          )}
          <Box marginTop={5} sx={{ display: 'flex', justifyContent: 'center' }}>
            {!isOnMobileBreakpoint && (
              <StyledButton variant="contained" onClick={props.onClose}>
                Przejdź do poradnika
              </StyledButton>
            )}
            <CloseButton variant="outlined" onClick={props.onClose}>
              Zamknij
            </CloseButton>
          </Box>
        </Box>
      )}
    </ConfirmingDialog>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  lineHeight: 1.5,
  backgroundColor: '#00B0F0',
  borderRadius: '30px',
  '&:hover': {
    backgroundColor: '#4cc7f4',
    boxShadow: 'none'
  },
  width: '220px',
  marginRight: 25
}));

const CloseButton = styled(Button)(({ theme }) => ({
  border: '2px solid white',
  borderRadius: '20px',
  color: '#ffffffaa',
  width: '220px',
  [theme.breakpoints.up('sm')]: {
    width: '150px'
  }
}));

const ConfirmingDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(171deg, rgba(0,34,84,1) 0%, rgba(9,77,136,1) 100%)',
    padding: 50,
    paddingBottom: 30,
    color: 'white',
    width: '92vw',

    fontWeight: 'bold',

    [theme.breakpoints.up('sm')]: {
      paddingBottom: 65,
      width: '85vw',
      maxWidth: '950px'
    }
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3)
    }
  }
}));

export interface ActionCloseIconProps {
  children?: React.ReactNode;
  onClose: () => void;
}

function ActionCloseIcon(props: ActionCloseIconProps) {
  const { onClose } = props;

  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      size="small"
      sx={{
        position: 'absolute',
        right: { xs: 17, md: 25 },
        top: { xs: 17, md: 25 },
        fontSize: 14,
        fontWeight: 'bold',
        color: 'rgba(0,34,84,1)',
        backgroundColor: 'white'
      }}
    >
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );
}

function MainIcon() {
  return (
    <Box
      sx={{
        color: '#00B0F0',
        fontSize: 45,
        width: 32,
        height: 32,
        backgroundColor: 'white',
        borderRadius: '50px'
      }}
    >
      <CheckCircleIcon
        fontSize="inherit"
        sx={{
          transform: 'translate(-12%,-27%)'
        }}
      />
    </Box>
  );
}
