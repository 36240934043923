import { ISection, ISectionType } from '../types';

// Info: Names of icons come form '/public/fonts/VW_Pdf_Icons'
export type IPdfIcon = 'engine' | 'edit' | 'seat' | 'wheel' | 'car' | 'contactdealer';

export type IPdfPageHeaderProps = {
  type: ISectionType;
  title: string;
  subtitle?: string;
  icon?: IPdfIcon;
  children?: React.ReactNode;
};

export const getPdfPageHeaders = (data: {
  sections: ISection[];
  technicalDataSubline: string;
}): IPdfPageHeaderProps[] => {
  return data.sections
    .filter((section) => section.type !== 'offerbox' && section.type !== 'box')
    .map((section) => {
      let icon: IPdfIcon | undefined;
      switch (section.type) {
        case 'models':
          icon = 'engine';
          break;
        case 'exteriors':
          icon = 'edit';
          break;
        case 'interiors':
          icon = 'seat';
          break;
        case 'wheels':
          icon = 'wheel';
          break;
        case 'features':
          icon = 'car';
          break;
        case 'technicals':
          icon = 'contactdealer';
          break;
        default:
          icon = undefined;
      }

      return {
        type: section.type,
        title: section.title,
        subtitle: section.type === 'technicals' ? data.technicalDataSubline : section.subtitle ?? '',
        icon: icon
      };
    });
};
