import { IPricelist } from '../types';
import { assetUrl, applyAssetUrl } from '../helpers/assetUrl';

export async function fetchPricelists() {
  const response = await fetch(assetUrl('/pricelists/pricelists.json'));
  const json = await response.json();

  return json.data;
}

export default async function fetchList() {
  const pricelists = (await fetchPricelists()) as IPricelist[] | undefined;

  const pathname =
    window.location.pathname.search('html') > 0
      ? window.location.pathname
      : window.location.pathname.replace('pdf', 'html');

  const pricelist = pricelists
    ?.filter((pricelist) => `/${pricelist.slug.toLowerCase()}` === pathname.toLowerCase())
    ?.at(0);

  if (pricelist) {
    document.title = pricelist.name;
    const responsePricelist = await fetch(assetUrl(pricelist.dataUrl));
    const json = await responsePricelist.json();

    if (json) {
      return applyAssetUrl(json);
    }

    return json;
  }
}
