import { useState } from 'react';
import { List, ListItemButton, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import { convertEngineToText } from '../../../helpers/convertToText';
import { ICalculatorEngine } from '../../../types';

export type IEnginesListProps = {
  visibility: 'block' | 'none';
  productSlug: string;
  selectedEngineText: string;
  engines: ICalculatorEngine[] | null;
  onSelectEngine: (engine: ICalculatorEngine) => void;
};

export const EnginesList = (props: IEnginesListProps) => {
  const [open, setOpen] = useState(false);
  const { productSlug } = props;

  const selectedProductHandler = (engine: ICalculatorEngine) => {
    props.onSelectEngine(engine);
    setOpen(false);
  };

  return (
    <List
      sx={{ display: props.visibility, width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={() => setOpen(!open)} sx={{ px: 0.5 }} disabled={!productSlug}>
        <ListItemText>
          <Typography color="#184185" fontWeight={700}>
            {!productSlug
              ? 'Brak dla wybranego produktu'
              : props.selectedEngineText
                ? props.selectedEngineText
                : `Wybierz silnik z listy dostępnych: ${props.engines?.length}`}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.engines?.map((engine, index) => (
          <List key={engine.id} onClick={() => selectedProductHandler(engine)} component="div" disablePadding>
            <ListItemButton
              sx={{
                backgroundColor: index % 2 ? 'white' : '#66749122',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start'
              }}
            >
              <ListItemText>
                <Typography color="#184185" fontWeight={500}>
                  {convertEngineToText(engine)}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        ))}
      </Collapse>
    </List>
  );
};
