import * as React from 'react';
import { useState, useEffect } from 'react';
import { Typography, Grid, Theme, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Text } from '@volkswagen-onehub/components-core';

import { useWindowDimensions } from '../../../utils/hooks/useWindowDimensions';
import { ISection, IDetailsSectionType } from '../../../types';
import { Table } from '../../Table';

function a11yProps(index: any) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`
  };
}

export type IExteriorsAndInteriorsProps = {
  type: IDetailsSectionType;
  children?: React.ReactNode;
  data: ISection;
};

export const ExteriorsAndInteriors = (props: IExteriorsAndInteriorsProps) => {
  const classes = useStyles();
  const { isOnMobileBreakpoint } = useWindowDimensions();
  const [selectedTab, setSelectedTab] = useState(0);
  const [equipmentLines, setEquipmentLines] = useState<string[]>([]);

  useEffect(() => {
    if (props.data) {
      const lines = new Set<string>();
      props.data.groups?.forEach((group) => {
        group.items.forEach((item) => {
          if (item.availability) {
            Object.keys(item.availability).forEach((line) => lines.add(line));
          }
        });
      });
      setEquipmentLines(Array.from(lines));
    }
  }, [props.data]);

  const handleChangeIndex = (index: number) => {
    setSelectedTab(index);
  };

  if (!props.data) {
    return null;
  }

  const FIRST_GRID_SIZE = 3.2;
  const gridLength = equipmentLines ? equipmentLines.length : 3;

  return (
    <>
      <div>
        {isOnMobileBreakpoint ? (
          <Tabs
            value={selectedTab}
            className={classes.tabs}
            indicatorColor="primary"
            textColor="primary"
            aria-label="exteriors and interiors tabs"
            scrollButtons
            variant="scrollable"
            allowScrollButtonsMobile
          >
            {equipmentLines.map((line, index) => (
              <Tab
                key={`${line}-${index}`}
                label={line}
                className={classes.tab}
                onClick={() => handleChangeIndex(index)}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        ) : (
          <Grid container className={classes.preTable}>
            <Grid item xs={FIRST_GRID_SIZE} className={classes.preTableCell1}></Grid>
            {equipmentLines?.map((equipment: string, index: number) => (
              <Grid
                item
                xs={(12 - FIRST_GRID_SIZE) / gridLength}
                className={classes.preTableCell2}
                key={`top-${equipment}`}
              >
                <div>{equipment}</div>
              </Grid>
            ))}
          </Grid>
        )}

        <div>
          {props?.data?.groups?.map((group, index) => (
            <div key={group?.name}>
              <div style={{ margin: '16px 0 14px 16px' }}>
                <Typography variant="h5" className={classes.tableTitle}>
                  <Text bold>{group?.name}</Text>
                </Typography>
              </div>
              <Table
                type={props.type}
                section={props.data}
                group={group}
                equipmentLines={equipmentLines}
                selectedMobileCol={selectedTab}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  table: {
    borderTop: '2px solid #001E50',
    borderBottom: '2px solid #001E50',
    '& :last-child': {
      borderRight: '2px solid #ffffff'
    }
  },

  preTable: {
    marginTop: '40px',
    borderBottom: '2px solid #001E50',
    '& :last-child': {
      borderRight: '2px solid #ffffff'
    },
    position: 'sticky',
    top: '50px',
    zIndex: 1,
    background: '#fff'
  },

  preTableCell1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRight: '2px solid #001E50',
    padding: '20px 16px',
    '& :last-child': {
      fontSize: 12,
      fontWeight: 400,
      paddingTop: 10
    }
  },
  preTableCell2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 700,
    borderRight: '2px solid #DFE4E8',
    padding: '3px'
  },

  tabs: {
    margin: '0 40px',
    height: 48,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  tab: {
    textTransform: 'none',
    height: 48
  },
  tabBreak: {
    backgroundColor: '#DFE4E8',
    marginTop: 8,
    width: 1,
    height: 32
  },

  tableTitle: {
    fontSize: 20,
    fontWeight: 700,
    display: 'block',
    marginRight: 9,
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block'
    }
  },
  tableSubTitle: {
    fontSize: 14,
    fontWeight: 400,
    display: 'block'
  },

  cell: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 700,
    color: '#001E50',
    padding: '16px 16px',
    borderBottom: '2px solid #DFE4E8'
  }
}));
