// @ts-nocheck
import * as React from 'react';
import { Text, View, Page } from '@react-pdf/renderer';

export type ILoadingWrapperProps = {
  name: string;
  isGenerating: boolean;
  children?: React.ReactNode;
};

export const LoadingWrapper = (props: ILoadingWrapperProps) => {
  return props.isGenerating ? (
    <Page size="A4">
      <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%' }}>
        <Text>Generowanie PDF dla {props.name}...</Text>
      </View>
    </Page>
  ) : (
    <>{props.children}</>
  );
};
