import { useState, useEffect } from 'react';
import { Theme, AppBar, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useWindowDimensions } from '../../utils/hooks/useWindowDimensions';
import { useAppContext } from '../../hooks/useAppContext';

const scrollIntoViewWithOffset = (selector: string, offset: number) => {
  const selectorTop = document.getElementById(selector)?.getBoundingClientRect().top;
  if (selectorTop) {
    window.scrollTo({
      behavior: 'smooth',
      top: selectorTop - document.body.getBoundingClientRect().top - offset
    });
  }
};

export const Navigation = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [show, setShow] = useState(false);
  const { isOnMobileBreakpoint } = useWindowDimensions();
  const { data } = useAppContext();

  const detailsSectionHeadings = data?.sections
    ?.filter((section) => section.type !== 'offerbox' && section.type !== 'box')
    .map((section) => {
      return {
        type: section.type,
        title: section.title,
        subtitle: section.subtitle ?? ''
      };
    });

  const getHeadings = (headings?: any[]) => headings ?? [{ type: '', title: '', subtitle: '' }];

  const detailSectionHeadingsWithForm = [
    ...getHeadings(detailsSectionHeadings),
    {
      type: 'form',
      title: 'Kontakt',
      subtitle: ''
    }
  ];

  useEffect(() => {
    const handleScroll = (event: Event) => {
      const sections = document.querySelectorAll(
        '#models,#exteriors,#interiors,#wheels,#promoFeatures,#features,#technicals,#dimensions,#accessories,#form'
      );
      sections.forEach((section, index) => {
        const { top, bottom } = section.getBoundingClientRect();
        if (top <= 200 && bottom >= 0) {
          handleChange(null, index);
        }
      });
      window.scrollY < 50 ? setShow(false) : setShow(true);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };

  return (
    <AppBar position="fixed" color="default" className={`${classes.appbar} ${show ? classes.show : classes.hide}`}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable prevent tabs example"
        scrollButtons={false}
        variant="scrollable"
        className={classes.tabs}
      >
        {detailSectionHeadingsWithForm.map((detailSectionHeading) => (
          <Tab
            key={detailSectionHeading?.type}
            label={<span className={classes.tabs}>{detailSectionHeading?.title}</span>}
            component="a"
            href={`/#${detailSectionHeading?.type}`}
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              event.preventDefault();
              scrollIntoViewWithOffset(detailSectionHeading?.type, isOnMobileBreakpoint ? -7 : -50);
            }}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {
    height: 50,
    padding: '0 20px',
    backgroundColor: 'white',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center'
    }
  },
  show: {
    visibility: 'visible',
    opacity: 1,
    transition: 'visibility 0.2s, opacity 0.5s linear'
  },
  hide: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0.2s, opacity 0.2s linear'
  },
  tabs: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#001E50',
    '& .MuiTabs-indicator': {
      backgroundColor: '#001E50',
      height: 3
    },
    '& .MuiTab-root.Mui-selected': {},
    '& .MuiTab-root': {}
  }
}));
