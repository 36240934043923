import { createContext, ReactNode, useState } from 'react';

import { ICalculatorContextType, ICalculatorResults } from './types';

const CalculatorContext = createContext<ICalculatorContextType | null>(null);

function CalculatorProvider({ children }: { children: ReactNode }) {
  const [calculationResults, setCalculationResults] = useState<ICalculatorResults>({
    results: [
      {
        engineBaseCode: '',
        installment: 0,
        priceNet: 0
      }
    ],
    percentage: 1,
    omnibus: true
  });

  return (
    <CalculatorContext.Provider value={{ calculationResults, setCalculationResults }}>
      {children}
    </CalculatorContext.Provider>
  );
}

export { CalculatorContext, CalculatorProvider };
