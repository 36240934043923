// @ts-nocheck
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import { CircularProgress } from '@mui/material';

import { ISection, ISectionType, IGroup } from '../types';
import { useAppContext } from '../hooks/useAppContext';
import { IPdfPageHeaderProps, PageWrapper, getPdfPageHeaders, LoadingWrapper } from '../pdf';
import { PdfModelsAndFeatures, PdfExteriorsAndInteriors, PdfWheels, PdfTechnicals } from '../pdf/components';

Font.register({
  family: 'VWHead',
  fonts: [
    {
      src: '/fonts/VW_Head/VWHeadWeb-Regular.ttf',
      fontWeight: 400
    },
    {
      src: '/fonts/VW_Head/VWHeadWeb-Bold.ttf',
      fontWeight: 700
    }
  ]
});

export function PDFrenderer() {
  const { data, isLoading } = useAppContext();
  const [isGenerating, setIsGenerating] = useState(false);
  const pdfCalculationResults = localStorage.getItem('calculationResults');

  useEffect(() => {
    const handleGeneratePDF = async () => {
      setIsGenerating(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsGenerating(false);
    };

    handleGeneratePDF();
  }, []);

  if (!data || isLoading) return <CircularProgress />;

  document.title = `${data.name} PDF`;

  const pdfPageHeaders = getPdfPageHeaders(data);

  const getPdfPageHeader = (type: ISectionType) =>
    pdfPageHeaders.find((pdfPageHeader) => pdfPageHeader.type === type) as IPdfPageHeaderProps;

  const getPdfHeaderWithContext = (type: ISectionType) => ({
    appData: data,
    calculationResults: JSON.parse(pdfCalculationResults),
    pdf: getPdfPageHeader(type)
  });
  const getSectionData = (type: ISectionType) => data.sections?.find((sections) => sections.type === type) as ISection;

  const getSectionDataWithContext = (type: ISectionType) => ({
    calculationResults: JSON.parse(pdfCalculationResults),
    data: getSectionData(type)
  });

  const getGroupsData = (type: ISectionType) =>
    data.sections?.find((sections) => sections.type === type)?.groups as IGroup[];

  return (
    <div style={{ backgroundColor: '#333' }}>
      <PDFViewer style={styles.viewer}>
        <Document title={data.name}>
          <LoadingWrapper name={data.name} isGenerating={isGenerating}>
            <PageWrapper {...getPdfHeaderWithContext('models')}>
              <PdfModelsAndFeatures type="models" {...getSectionDataWithContext('models')} />
            </PageWrapper>
            <PageWrapper {...getPdfHeaderWithContext('exteriors')}>
              <PdfExteriorsAndInteriors type="exteriors" {...getSectionDataWithContext('exteriors')} />
            </PageWrapper>
            <PageWrapper {...getPdfHeaderWithContext('interiors')}>
              <PdfExteriorsAndInteriors type="interiors" {...getSectionDataWithContext('interiors')} />
            </PageWrapper>
            <PageWrapper {...getPdfHeaderWithContext('wheels')}>
              <PdfWheels type="wheels" {...getSectionDataWithContext('wheels')} />
            </PageWrapper>
            <PageWrapper {...getPdfHeaderWithContext('features')}>
              <PdfModelsAndFeatures type="features" {...getSectionDataWithContext('features')} />
            </PageWrapper>
            <PageWrapper {...getPdfHeaderWithContext('technicals')}>
              {getGroupsData('technicals').map((group, index) => (
                <PdfTechnicals key={`pdf-technical-${index}`} type="technicals" data={group} />
              ))}
            </PageWrapper>

            {/* <PageWrapper {...getPdfHeaderWithContext('offerbox')}>{section}</PageWrapper>
             */}
          </LoadingWrapper>
        </Document>
      </PDFViewer>
    </div>
  );
}

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight - 7
  },
  section: {
    backgroundColor: '#aaa'
  }
});
