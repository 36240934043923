import * as React from 'react';
import { Text } from '@volkswagen-onehub/components-core';
import { Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { IDetailsSectionType } from '../../types';

export type IDetailsSectionProps = {
  type: IDetailsSectionType;
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
};

export const DetailsSection = (props: IDetailsSectionProps) => {
  const classes = useStyles();

  return (
    <section id={props.type} className={classes.container}>
      <Typography variant="h2" className={classes.h2} align="center">
        <Text bold>{props.title}</Text>
      </Typography>
      <Typography variant="h3" className={classes.h3} align="center">
        <Text>
          <span style={{ color: '#5a5a5a' }}>{props?.subtitle ?? ''}</span>
        </Text>
      </Typography>
      <div className={classes.content}>{props.children}</div>
    </section>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 50,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 100,
      maxWidth: '1440px',
      margin: '0 auto'
    }
  },
  content: {
    margin: '0 8px',
    [theme.breakpoints.up('sm')]: {
      margin: '0 60px'
    }
  },
  h2: {
    fontSize: 32,
    [theme.breakpoints.up('sm')]: {
      fontSize: 48
    }
  },
  h3: {
    fontSize: 20,
    marginTop: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24
    }
  }
}));
