import { Fragment, useState } from 'react';
import { Tooltip as MUITooltip, Typography, styled, Box, Theme } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { CheckCircle as CheckCircleIcon, RemoveCircleOutline as RemoveCircleIcon } from '@mui/icons-material';
import * as React from 'react';
import { makeStyles } from '@mui/styles';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} arrow classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#b5b5b9'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 440,
    fontSize: theme.typography.pxToRem(13),
    border: '1px solid #dadde9',
    boxShadow: theme.shadows[4],
    padding: '8px 10px'
  }
}));

export type ITooltipProps = {
  type: 'header' | 'absolute' | 'convert' | 'component';
  data?: any;
};

export const Tooltip = (props: ITooltipProps) => {
  const [isOnHover, setIsOnHover] = useState(false);
  const classes = useStyles();

  if (!props.data) {
    return null;
  }

  interface IImgStyle {
    [key: string]: React.CSSProperties;
  }

  const imgStyle: IImgStyle = {
    header: { transform: 'translateY(5px)' },
    // absolute: { transform: 'translateY(-50%)', position: 'absolute', top: '50%', right: 5 }
    absolute: { position: 'absolute', top: 10, right: 7 },
    convert: { position: 'absolute', top: 10, right: 7 },
    component: { position: 'absolute', top: 10, right: 7 }
  };

  return (
    <HtmlTooltip
      title={
        props.type === 'header' ? (
          <Fragment>
            <Typography color="inherit" fontSize={13} fontWeight="bold">
              {props.data}
            </Typography>
          </Fragment>
        ) : props.type === 'absolute' ? (
          <Fragment>
            <div dangerouslySetInnerHTML={{ __html: props.data }} />
          </Fragment>
        ) : props.type === 'convert' ? (
          <Fragment>
            <table className={classes.availableTable}>
              <tbody>
                <tr>
                  <th colSpan={2}>Dostępność: </th>
                </tr>
                {Object.keys(props.data).map((availabilityKey) => (
                  <tr key={availabilityKey}>
                    <td>{availabilityKey}</td>
                    <td>
                      {props.data[availabilityKey] ? (
                        <CheckCircleIcon fontSize={'small'} sx={{ verticalAlign: 'bottom', marginLeft: '5px' }} />
                      ) : (
                        <RemoveCircleIcon fontSize={'small'} sx={{ verticalAlign: 'bottom', marginLeft: '5px' }} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Fragment>
        ) : props.type === 'component' ? (
          <Box>{props.data}</Box>
        ) : null
      }
    >
      <img
        onMouseEnter={() => setIsOnHover(true)}
        onMouseLeave={() => setIsOnHover(false)}
        src={`/fonts/VW_Pdf_Icons/info${isOnHover ? '-filled' : ''}.png`}
        alt="info"
        style={{ width: 24, height: 24, cursor: 'pointer', ...{ ...imgStyle[props.type] } }}
      />
    </HtmlTooltip>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  availableTable: {
    borderCollapse: 'collapse',
    '& th': {
      textAlign: 'left'
    },
    '& td': {
      paddingTop: '5px',
      fontSize: '12px',
      fontWeight: 400
    }
  }
}));
