import { Box, styled, Dialog } from '@mui/material';
import CircularProgress, { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress';

export type ILoadingModalProps = {
  open: boolean;
  children?: React.ReactNode;
};

export const LoadingModal = (props: ILoadingModalProps) => {
  return (
    <LoadingDialog open={props.open}>
      <FacebookCircularProgress />
      <Box paddingTop={2.5}>{props.children}</Box>
    </LoadingDialog>
  );
};

const LoadingDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(171deg, rgba(0,34,84,1) 0%, rgba(9,77,136,1) 100%)',
    padding: 50,
    color: 'white',
    width: '70vw',
    height: '70vw',
    fontWeight: 'bold',

    [theme.breakpoints.up('sm')]: {
      width: 250,
      height: 250
    }
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3)
    }
  }
}));

function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{ color: '#82828288' }}
        size={55}
        thickness={2.5}
        {...props}
        value={100}
      />
      <CircularProgress
        disableShrink
        sx={{
          color: 'white',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={55}
        thickness={2.5}
        {...props}
      />
    </Box>
  );
}
