// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import { IFormInput } from './type';
import { IData } from '../../types';
import { getGMTString } from '../../helpers/dateTime';

declare const window: Window & typeof globalThis & { visitor: any };

export const getIp = async () => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();

    return data.ip;
  } catch (err) {
    return '193.93.107.58'; // Public VW Prod. IP
  }
};

export const convertToLeadFormat = (formInputsData: IFormInput, data: IData | undefined) => {
  const asArray = Object.entries(formInputsData);
  const filteredFields = asArray.filter(([key, value]) => typeof value === 'string' && value.length);
  filteredFields.push(
    ['carInfo', `Rok modelowy: ${data?.modelYear}, Rok produkcji: ${data?.buildYear}`],
    ['carModel', data?.modelName ?? ''],
    ['actionType', 'JP_OF'],
    ['AAVisitorID', window.visitor?.getMarketingCloudVisitorID() ?? '']
  );

  const formFieldArray = filteredFields.map((obj) => ({
    formFieldCode: obj.at(0),
    formFieldValue: obj.at(1)
  }));

  const filteredAgreements = asArray.filter(([key, value]) => typeof value === 'boolean' && value);

  const marketingCodes = {
    checkboxMarketing: ['gdpr-answer-direct-marketing-importer', 'gdpr-answer-marketing-messages-importer'],
    checkboxEmail: ['gdpr-answer-email'],
    checkboxSMS: ['gdpr-answer-electronic-messages'],
    checkboxPhone: ['gdpr-answer-voice-calls']
  };

  const marketingAgreementsArray = filteredAgreements
    .map((agreement) =>
      Object.entries(marketingCodes)
        .find((code) => code.at(0) === agreement.at(0))
        ?.at(1)
    )
    .flat();

  const marketingAgreements = {
    agreementId: 1031,
    agreementCodes: marketingAgreementsArray
  };

  return { formData: formFieldArray, marketingAgreements: marketingAgreements };
};

export const getLeadData = async (formInputsData: IFormInput, data: IData | undefined) => {
  const ip = await getIp();
  const { formData, marketingAgreements } = convertToLeadFormat(formInputsData, data);

  return {
    requestGuid: uuidv4(),
    formCode: 'VWBPCEN',
    brand: 'V',
    formDateTime: getGMTString(),
    marketingAgreements,
    formData,
    IPAddress: ip,
    channel: 'internet',
    url: window.location.href
  };
};

export const handleRegex = (value: string) => {
  let isError = true;

  const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
  if (value === '' || regex.test(value)) {
    isError = false;
  }

  return isError;
};
