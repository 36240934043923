import * as React from 'react';
import { useState } from 'react';

import { Typography, Grid, Theme, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Text } from '@volkswagen-onehub/components-core';
import { useWindowDimensions } from '../../../utils/hooks/useWindowDimensions';
import { ISection, IDetailsSectionType } from '../../../types';
import { Table } from '../../Table';

function a11yProps(index: any) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`
  };
}

export type IModelsAndFeaturesProps = {
  type: IDetailsSectionType;
  children?: React.ReactNode;
  data: ISection;
};

export const ModelsAndFeatures = (props: IModelsAndFeaturesProps) => {
  const classes = useStyles();

  const [selectedMobileCol, setSelectedMobileCol] = useState(0);
  const { isOnMobileBreakpoint } = useWindowDimensions();

  const handleChangeIndex = (index: number) => {
    setSelectedMobileCol(index);
  };

  if (!props.data) {
    return null;
  }

  const gridLength = props.data.equipmentLines ? props.data.equipmentLines.length : 3;
  const FIRST_GRID_SIZE = 3.2;

  return (
    <div>
      {isOnMobileBreakpoint ? (
        <Tabs
          value={selectedMobileCol}
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          aria-label="action tabs example"
          scrollButtons
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {props?.data?.equipmentLines?.map((equipment: string, index: number) => (
            <Tab
              key={`${equipment}-${index}`}
              onClick={() => handleChangeIndex(index)}
              label={equipment}
              className={classes.tab}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      ) : (
        <Grid container className={classes.preTable}>
          <Grid item xs={FIRST_GRID_SIZE} className={classes.preTableCell1}></Grid>
          {props?.data?.equipmentLines?.map((equipment: string, index: number) => (
            <Grid
              item
              xs={(12 - FIRST_GRID_SIZE) / gridLength}
              className={classes.preTableCell2}
              key={`top-${equipment}`}
            >
              <div>{equipment}</div>
            </Grid>
          ))}
        </Grid>
      )}

      <div>
        {props?.data?.groups?.map((group, index) => (
          <div key={group?.name}>
            <div style={{ margin: '16px 0 14px 16px' }}>
              <Typography variant="h5" className={classes.tableTitle}>
                <Text bold>{group?.name}</Text>
              </Typography>
              {props.type === 'models' && (
                <div className={classes.tableSubTitle}>
                  <Text>
                    <span style={{ color: 'grey' }}>
                      Pojemność | {group?.name === 'Plug-In-Hybrid' ? 'ŁĄCZNA MOC' : 'MOC'} | Skrzynia biegów
                    </span>
                  </Text>
                </div>
              )}
            </div>
            <Table type={props.type} section={props.data} group={group} selectedMobileCol={selectedMobileCol} />
          </div>
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  preTable: {
    marginTop: '40px',
    borderBottom: '2px solid #001E50',
    '& :last-child': {
      borderRight: '2px solid #ffffff'
    },
    position: 'sticky',
    top: '50px',
    zIndex: 1,
    background: '#fff'
  },

  preTableCell1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRight: '2px solid #001E50',
    padding: '20px 16px',
    '& :last-child': {
      fontSize: 12,
      fontWeight: 400,
      paddingTop: 10
    }
  },
  preTableCell2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 700,
    borderRight: '2px solid #DFE4E8',
    padding: '3px'
  },

  tabs: {
    margin: '0 40px',
    height: 48,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  tab: {
    textTransform: 'none',
    height: 48
  },
  tabBreak: {
    backgroundColor: '#DFE4E8',
    marginTop: 8,
    width: 1,
    height: 32
  },
  header: {
    textAlign: 'center',
    backgroundColor: theme.palette.grey[200]
  },
  h2: {
    fontSize: 32,
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: 48
    }
  },
  h3: {
    fontSize: 20,
    fontWeight: 400,
    fontStyle: 'italic',

    [theme.breakpoints.up('sm')]: {
      fontSize: 24
    }
  },

  tableTitle: {
    fontSize: 20,
    fontWeight: 700,
    display: 'block',
    marginRight: 9,
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block'
    }
  },
  tableSubTitle: {
    fontSize: 14,
    fontWeight: 400,
    display: 'block'
  }
}));
