import { useEffect } from 'react';

export const useCreateAndRemoveEventListener = (
  eventName: string,
  handler: () => void,
  options?: AddEventListenerOptions
) => {
  useEffect(() => {
    window.addEventListener(eventName, handler, options);

    return () => {
      window.removeEventListener(eventName, handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
