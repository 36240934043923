// @ts-nocheck
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import { IPageWrapperProps } from './index';

type IPdfHeaderProps = IPageWrapperProps;

export const PdfHeader = (props: IPdfHeaderProps) => {
  const pdfCalculatorParameters = JSON.parse(localStorage.getItem('calculatorParameters'));

  const getCalculatorParametersText = (parameters: any) => {
    const period = parameters.fundingPeriod;
    const payment =
      parameters.downPaymentOption === 'Procent'
        ? parameters.ownPayment * 100
        : parameters.downPaymentAmount?.toLocaleString('sk-SK');
    const paymentParameter = parameters.downPaymentOption === 'Procent' ? '%' : 'zł';
    const repurchaseOrLimit = parameters.repurchase
      ? `wykupu ${(parameters.repurchase * 100).toLocaleString('sk-SK')}%`
      : `limitu ${parameters.kilometerLimit.toLocaleString('sk-SK')}km`;

    return `Okres finansowania ${period}mc / Opłata wstępna ${payment}${paymentParameter} / Wartość ${repurchaseOrLimit}`;
  };

  return (
    <>
      <View style={styles.header} fixed>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <View>
            <Text style={{ fontFamily: 'VWHead' }}>Nowy {props.appData.name}</Text>
          </View>
          {props.calculationResults.percentage !== 1 && (
            <View style={{ fontSize: 10 }}>
              <View style={{ alignSelf: 'center' }}>
                <Text>{pdfCalculatorParameters?.selectedProductName}</Text>
              </View>
              <View>
                <Text>{getCalculatorParametersText(pdfCalculatorParameters)}</Text>
              </View>
            </View>
          )}
          <View style={{ height: 27, width: 27, alignSelf: 'center', justifyContent: 'center' }}>
            <Image
              source="/fonts/VW_Pdf_Icons/logoVW.png"
              style={{ height: 24, width: 24, alignSelf: 'center', justifyContent: 'center' }}
            />
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 6,
            marginTop: 8
          }}
        >
          <View>
            <Text>
              Rok modelowy: {props.appData.modelYear} | Rok produkcji: {props.appData.buildYear}
            </Text>
          </View>
          <View>
            <Text>Cennik ważny od {props.appData.validFrom}</Text>
          </View>
        </View>
      </View>
      <View style={styles.headerBottom} fixed>
        {props.pdf.icon && <Image source={`/fonts/VW_Pdf_Icons/${props.pdf.icon}-light.png`} style={styles.icon} />}
        <View>
          <Text style={{ fontFamily: 'VWHead', fontSize: '12px' }}>{props.pdf.title}</Text>
          <Text style={{ fontSize: '6px', color: '#6A767D' }}>{props.pdf.subtitle ?? ''}</Text>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    height: '90px',
    width: '100%',
    backgroundColor: '#F3F5F6',
    padding: 24,
    color: '#001E50',
    position: 'absolute',
    top: 0,
    transform: 'translateY(-15%)'
  },
  headerBottom: {
    color: '#001E50',
    height: '38px',
    width: '547px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    position: 'absolute',
    top: '75px',
    paddingVertical: 6,
    paddingHorizontal: 48,
    marginHorizontal: 24,
    transform: 'translateY(-20%)',
    border: '1px solid #ddd'
  },
  icon: {
    height: 20,
    width: 20,
    marginRight: 16
  }
});
