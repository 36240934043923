import { ReactNode } from 'react';

import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderVW } from '@volkswagen-onehub/components-core';

type ThemeConfigProps = {
  children: ReactNode;
};

export function ThemeConfig({ children }: ThemeConfigProps) {
  const theme = createTheme();

  return (
    <ThemeProviderVW>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </ThemeProviderVW>
  );
}
