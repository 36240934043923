import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ThemeConfig } from './theme';
import { Layout } from './layouts';

import { Main, PDFrenderer, PricelistSelector } from './pages';
import { AppProvider } from './contexts/AppContext';
import { CalculatorProvider } from './contexts/CalculatorContext';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000
    }
  }
});

const router = createBrowserRouter([
  {
    path: window.location.pathname,
    element:
      window.location.pathname.search(/\.html$/) > 0 ? (
        <Layout>
          <Main />
        </Layout>
      ) : window.location.pathname === '/' ? (
        <PricelistSelector />
      ) : (
        <PDFrenderer />
      )
  }
]);

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <CalculatorProvider>
          <ThemeConfig>
            <RouterProvider router={router} />
          </ThemeConfig>
        </CalculatorProvider>
      </AppProvider>

      {/* react query for develop */}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}
