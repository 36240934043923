import { createContext, ReactNode, useEffect, useState } from 'react';

import { useList } from '../hooks/useList';
import { IAppContextType } from './types';

const AppContext = createContext<IAppContextType | null>(null);

function AppProvider({ children }: { children: ReactNode }) {
  const { data: appData, isLoading: isLoadingAppData, isError: isErrorAppData } = useList();

  const [appListData, setAppListData] = useState<IAppContextType>({
    data: appData,
    isLoading: isLoadingAppData,
    isError: isErrorAppData
  });

  useEffect(() => {
    setAppListData({
      data: appData,
      isLoading: isLoadingAppData,
      isError: isErrorAppData
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  return <AppContext.Provider value={{ ...appListData }}>{children}</AppContext.Provider>;
}

export { AppContext, AppProvider };
