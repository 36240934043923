import * as React from 'react';
import { useState } from 'react';
import { Box, SpeedDial, SpeedDialIcon, SpeedDialAction, Fab, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import SegmentIcon from '@mui/icons-material/Segment';

import './style.css';
import { INbab } from '../../types';

export type IOpenNbabSpeedDial = {
  nbab: INbab[];
  children?: React.ReactNode;
};

export const OpenNbabSpeedDial = (props: IOpenNbabSpeedDial) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const nbabPdf: INbab = {
    label: 'Pobierz cennik',
    url: window.location.href.replace('html', 'pdf'),
    icon: 'vw-pl-download'
  };

  const nbabArrayWithPdf = [...props.nbab];

  return (
    <Box className={classes.root}>
      <SpeedDial
        ariaLabel="Next Best Button Action"
        className={classes.speedDial}
        open={isOpen}
        onClick={() => setIsOpen((prev) => !prev)}
        onBlur={() =>
          setTimeout(() => {
            setIsOpen(false);
          }, 200)
        }
        FabProps={{
          sx: {
            height: 64,
            width: 64,
            bgcolor: '#4cc7f4',
            '&:hover': {
              bgcolor: '#00b0f0'
            }
          }
        }}
        icon={<SpeedDialIcon sx={{ color: '#001E50' }} icon={<MenuIcon />} openIcon={<SegmentIcon />} />}
      >
        {nbabArrayWithPdf.map((action, index, arr) => (
          <SpeedDialAction
            key={action.label}
            sx={{ width: 220, position: 'absolute', bottom: 12 + (index + 1) * 76, right: 0 }}
            icon={
              <a
                href={action.url}
                style={{ textDecoration: 'none' }}
                target={arr.length - 1 === index ? '_blank' : '_self'}
                rel="noreferrer"
              >
                <Fab variant="extended" className={classes.fab} aria-label="add">
                  <span className={`${action.icon} icon ${classes.icon}`} />
                  <span className={classes.label}>{action.label}</span>
                </Fab>
              </a>
            }
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    // width: 280,
    bottom: 34,
    right: 14,
    [theme.breakpoints.up('sm')]: {
      right: 18,
      bottom: 50
    },
    zIndex: 100
  },
  speedDial: {},
  label: {
    fontWeight: 'normal',
    textAlign: 'left',
    lineHeight: '16px'
  },
  icon: {
    margin: '0 16px 0 10px'
  },
  fab: {
    display: 'flex',
    justifyContent: 'start',
    backgroundColor: '#4CC7F4',
    color: '#001E50 !important',
    width: '220px',
    height: 64,
    borderRadius: '50px',
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#00b0f0'
    }
  }
}));
