import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { useWindowDimensions } from '../../utils/hooks/useWindowDimensions';
import { IImage, ISectionType, IItem } from '../../types';
import './style.css';

export type IGallery = {
  type: ISectionType;
  images?: IImage[];
  items?: IItem[];
};

export const Gallery = (props: IGallery) => {
  const classes = useStyles();
  const { isOnMobileBreakpoint } = useWindowDimensions();

  if (props.type === 'dimensions' && !props.images?.length) {
    return null;
  }

  // @ts-ignore
  const handleDragStart = (e) => e.preventDefault();

  const convertImagesToItems = (data?: IImage[]) => {
    const items = data?.map((image) => (
      <img
        key={image.type}
        src={image.imageUrl}
        alt={image.type}
        className={classes.image}
        onDragStart={(e) => handleDragStart(e)}
        role="presentation"
      />
    ));
    return items as any[] | undefined;
  };

  const convertItems = (data?: IItem[]) => {
    const items = data?.map((item, index) => (
      <Box key={item?.code} className={classes.item}>
        {item?.imageUrl ? (
          <img className={classes.itemImage} src={item?.imageUrl} alt={item?.name} />
        ) : (
          <Box className={classes.itemImagePlaceholder}>No photo</Box>
        )}
        <Box className={classes.itemText}>
          <Box>{item?.name}</Box>
          <Box>{item?.price} PLN</Box>
        </Box>
      </Box>
    ));

    return items as any[] | undefined;
  };

  const isControlsAndAutoPlay =
    props.type === 'dimensions' ||
    (props?.items && props?.items?.length && props?.items?.length >= 5) ||
    isOnMobileBreakpoint
      ? true
      : false;

  return (
    <Box style={{ position: 'relative' }} marginTop={props.type === 'accessories' ? 4 : 0}>
      <AliceCarousel
        mouseTracking
        disableDotsControls
        infinite={isControlsAndAutoPlay}
        // autoPlay={isControlsAndAutoPlay}
        animationEasingFunction="ease"
        autoPlayStrategy="action"
        animationDuration={props.type === 'dimensions' || isOnMobileBreakpoint ? 800 : 600}
        autoPlayInterval={props.type === 'dimensions' || isOnMobileBreakpoint ? 2200 : 1100}
        autoPlayDirection={props.type === 'dimensions' ? 'ltr' : 'rtl'}
        items={props.type === 'dimensions' ? convertImagesToItems(props?.images) : convertItems(props?.items)}
        responsive={{
          0: {
            items: 1
          },
          768: {
            items: props.type === 'dimensions' ? 1 : 5,
            itemsFit: 'contain'
          }
        }}
      />
      {isControlsAndAutoPlay ? (
        <>
          <Box component="span" className={`vw-pl-chevron-left control-icon-left`} />
          <Box component="span" className={`vw-pl-chevron-right control-icon-right`} />
        </>
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    display: 'flex',
    margin: 'auto',
    height: '42vw',
    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      height: 300
    }
  },
  item: {
    fontWeight: 'bold',
    color: '#001E50',
    fontSize: 16,
    lineHeight: 1.8,
    backgroundColor: '#e3e5e688',
    margin: '0 auto',
    width: '60vw',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      width: '90%'
    }
  },
  itemText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '45vw',
    padding: 15,
    [theme.breakpoints.up('sm')]: {
      padding: 12,
      height: '150px'
    }
  },
  itemImage: {
    height: '42vw',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      height: '130px'
    }
  },
  itemImagePlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '42vw',
    width: '100%',
    backgroundColor: 'grey',
    [theme.breakpoints.up('sm')]: {
      height: '130px'
    }
  }
}));
