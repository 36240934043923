import React, { Component } from 'react';

declare const window: Window & typeof globalThis & { vwa_d6_cms: any };

export class IntegratorMenu extends Component<any, any> {
  componentDidMount() {
    window.vwa_d6_cms = window.vwa_d6_cms || {};
    window.vwa_d6_cms.vwaAppendScriptAttribute = function (e: any, s: any) {
      if (s && s.length) {
        for (let i = 0; i < s.length; i++) {
          let script = document.createElement('script');
          script.setAttribute('src', s[i]);
          e.append(script);
        }
      }
    };

    window.vwa_d6_cms.vwaParseModel = function (s: any) {
      const scripts = document.getElementsByTagName('script');
      const lastScript = scripts[scripts.length - 1];
      const integratorModel = JSON.parse(s);
      window.vwa_d6_cms.integratorSpaModel = integratorModel.model;
      window.vwa_d6_cms.vwaAppendScriptAttribute(lastScript, integratorModel.scriptSources);
    };

    window.vwa_d6_cms.loadSources = function () {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          window.vwa_d6_cms.vwaParseModel(xhr.response);
        } else {
          console.log('The request failed!', xhr);
        }
      };
      xhr.open('GET', 'https://www.volkswagen.pl:443/pl/integratory/cennik.integrator-includes.txt?wcmmode=disabled');
      xhr.send();
    };

    window.vwa_d6_cms.loadSources();
  }

  render() {
    return (
      <>
        <div
          id="vwaUnsupportedBrowserNotification"
          data-heading="Your browser is outdated!"
          data-text='Please get a decent browser&lt;a href="https://www.mozilla.org/pl/firefox/new/" target="_blank"&gt;Firefox&lt;/a&gt; or &lt;a href="https://www.google.com/intl/pl_ALL/chrome/" target="_blank"&gt;Chrome&lt;/a&gt;'
        >
          <div id="vwaUBNHitarea"></div>
          <div className="vwaUBNModal">
            <div className="vwaUBNModalInner"></div>
          </div>
          <div id="vwaUBNCloseButton"></div>
        </div>
        <div id="vwa_d6_cms_integrator_csr_container"></div>
      </>
    );
  }
}
