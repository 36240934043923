import React, { useState } from 'react';
import { Text, TokenTextColor } from '@volkswagen-onehub/components-core';
import { Typography, Theme, Box, Button, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ISection } from '../../types';
import { useAppContext } from '../../hooks';
import { useWindowDimensions } from '../../utils/hooks/useWindowDimensions';
import { FormModal, ConfirmingModal } from '../';
import './style.css';

export type IOfferSectionProps = {
  type: 'offerbox';
  children?: React.ReactNode;
  data?: ISection;
};

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  marginTop: 35,
  padding: '16px 20px',
  lineHeight: 1.5,
  width: '100%',
  backgroundColor: '#4CC7F4dd',
  borderRadius: '30px',
  '&:hover': {
    backgroundColor: '#4CC7F4aa',
    boxShadow: 'none'
  }
});

export const OfferSection = (props: IOfferSectionProps) => {
  const classes = useStyles();
  const modelName = useAppContext().data?.modelName;
  const { isOnMobileBreakpoint } = useWindowDimensions();

  const [openDialog, setOpenDialog] = useState(false);
  const [visibleConfirmigModal, setVisibleConfirmigModal] = useState(false);
  const [errorOfSubmittedForm, setErrorOfSubmittedForm] = useState(false);

  const items = props.data?.items as Array<{ text: string; icon: string }> | null;
  const handleDialog = (flag: boolean) => setOpenDialog(flag);

  if (!props.data) {
    return null;
  }

  const submitFormModalHandler = (isVisibleConfirmigModal: boolean, isFormError: boolean) => {
    setOpenDialog(false);
    setVisibleConfirmigModal(isVisibleConfirmigModal);
    setErrorOfSubmittedForm(isFormError);
  };

  const closeConfirmingModalHandler = () => {
    setVisibleConfirmigModal(false);
  };

  return (
    <section className={classes.container}>
      <Typography variant="h2" className={classes.h2} align="center">
        <span>
          Aktualne
          <span style={{ fontWeight: 700 }}> oferty i promocje</span>
        </span>
      </Typography>
      <Box className={classes.offerbox}>
        <Box gridColumn={1} gridRow={isOnMobileBreakpoint ? 2 : 1} sx={{ margin: { xs: '0 32px 32px', md: '3vw' } }}>
          <Typography variant="h3" className={classes.h3} align="left">
            <Text color={TokenTextColor.inherit}>{props.data.title}</Text>
          </Typography>
          <Typography variant="h4" className={classes.h4} align="left">
            <Text color={TokenTextColor.inherit}>
              <Box marginTop={2} sx={{ fontSize: 17 }}>
                <div dangerouslySetInnerHTML={{ __html: props.data.description }} />
              </Box>
            </Text>
          </Typography>
          <Typography variant="h4" className={classes.h5} align="left">
            {items?.map((item) => (
              <Text key={item.text}>
                <Box
                  marginTop={isOnMobileBreakpoint ? 1 : '2vw'}
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: '#ffffffcc' }}
                >
                  <span className={`${item.icon} icon-offer`} />
                  <span dangerouslySetInnerHTML={{ __html: item.text }} />
                </Box>
              </Text>
            ))}
          </Typography>
          <Box sx={{ width: isOnMobileBreakpoint ? '100%' : '70%' }}>
            <BootstrapButton variant="contained" onClick={(e) => setOpenDialog(true)}>
              Zapytaj o ofertę
            </BootstrapButton>
          </Box>
        </Box>
        <Box gridColumn={isOnMobileBreakpoint ? 1 : 2} gridRow={1}>
          <Box
            sx={{
              position: 'relative',
              height: { xs: '83vw', md: '34vw' },
              width: { xs: '83vw', md: '40vw' },
              transform: { xs: 'translate(8.5vw,-13.5vw)', md: 'translate(0,0)' },
              overflow: { xs: 'hidden', md: 'visible' }
            }}
          >
            <Box
              component="img"
              sx={{
                height: { xs: '83vw', md: '28vw' },
                transform: { xs: 'translate(0)', md: 'translate(6vw,3vw)' }
              }}
              src={props.data.imageUrl}
              alt="offerbox"
            />
          </Box>
        </Box>
      </Box>

      {modelName && (
        <FormModal
          title={modelName}
          openModal={openDialog}
          onDialogState={handleDialog}
          onSubmitFormModalHandler={submitFormModalHandler}
        />
      )}
      <ConfirmingModal
        isError={errorOfSubmittedForm}
        open={visibleConfirmigModal}
        onClose={closeConfirmingModalHandler}
      />

      {props.children}
    </section>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 100,
    [theme.breakpoints.up('sm')]: {
      marginLeft: '4vw'
    }
  },
  offerbox: {
    display: 'grid',
    gridTemplateColumns: '100%',
    color: '#ffffff88',
    backgroundColor: '#001E50',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '2.5fr 3fr',
      maxWidth: '80vw'
    }
  },
  h3: {
    fontSize: 24,
    color: '#ffffffcc',
    [theme.breakpoints.up('sm')]: {
      fontSize: 30
    }
  },
  h2: {
    fontSize: 32,
    display: 'block',
    maxWidth: '95vw',
    marginBottom: '22vw',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 40,
      fontSize: 48
    }
  },
  h4: {
    fontWeight: 400,
    marginTop: 32,
    [theme.breakpoints.up('sm')]: {}
  },
  h5: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: 32
  }
}));
