import { useQuery } from 'react-query';
import { IData, IPricelist } from '../types';
import fetchList, { fetchPricelists } from '../queries/fetchList';

export const useList = () => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery<IData>(['list'], () => fetchList());

  return { data, isLoading, isError, isFetching, refetch };
};

export const usePricelists = () => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery<IPricelist[]>(['pricelists'], () =>
    fetchPricelists()
  );

  return { data, isLoading, isError, isFetching, refetch };
};
