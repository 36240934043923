import * as React from 'react';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useWindowDimensions } from '../../utils/hooks/useWindowDimensions';
import { IDetailsSectionType, IItem, ICategory } from '../../types';
import { Tooltip } from '../UI';

export type ITechnicalTableProps = {
  type: IDetailsSectionType;
  children?: React.ReactNode;
  category: ICategory;
  items: IItem[];
  selectedMobileCol: number;
};

export const TechnicalTable = (props: ITechnicalTableProps) => {
  const classes = useStyles();
  const { isOnMobileBreakpoint } = useWindowDimensions();
  let parameterResult: any = {};

  const getEngineDetails = () => {
    if (!parameterResult?.value.includes('lub')) {
      return null;
    }
    const rows = [];
    rows.push(
      <tr>
        <th colSpan={2} style={{ textAlign: 'left' }}>
          <div dangerouslySetInnerHTML={{ __html: parameterResult.category }} />
          <span style={{ fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: `(${parameterResult.name})` }} />
        </th>
      </tr>
    );
    for (let engine in parameterResult.details) {
      rows.push(
        <>
          <tr>
            <td>{engine}</td>
            <td style={{ textAlign: 'right' }}>{parameterResult.details[engine]}</td>
          </tr>
        </>
      );
    }

    return (
      <>
        <table className={classes.detailsTable}>{rows}</table>
      </>
    );
  };

  const findParameter = (item: IItem, parameter: string) => {
    parameterResult = item.parameters?.find(
      (itemParameter: any) => itemParameter.name === parameter && itemParameter.category === props.category.name
    );

    return parameterResult?.value?.replace(/(?<!\d) (000|,000|\.000)\s(mm|l|KM|kW)/g, ' $2');
  };

  return (
    <>
      {isOnMobileBreakpoint ? (
        <Grid container className={classes.table}>
          {props?.items.map((item, index) =>
            props.items[props.selectedMobileCol].model === item?.model ? (
              <Grid key={`${item.model}-${index}`} item xs={12}>
                {props.category?.parameters.map((parameter, index) => (
                  <Grid key={parameter} container className={index ? classes.td : ''}>
                    <Grid item xs={7} className={classes.cell1}>
                      <div>{parameter}</div>
                      <span></span>
                    </Grid>
                    <Grid item xs={5} className={classes.cell2}>
                      <div>{item.parameters?.find((itemParameter) => itemParameter.name === parameter)?.value}</div>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : null
          )}
        </Grid>
      ) : (
        <Grid container className={classes.table}>
          {props.category?.parameters.map((parameter, index) => (
            <Grid key={`${parameter}-${index}`} item xs={12}>
              <Grid container className={`${index ? classes.td : ''} ${classes.selection}`}>
                <Grid item xs={3} className={classes.cell1}>
                  <div>{parameter}</div>
                  <span></span>
                </Grid>
                {props.items.map((item, itemIndex) => (
                  <Grid
                    item
                    key={`${parameter}-${index}-${item.model}`}
                    xs={9 / props.items.length}
                    className={classes.cell2}
                    style={{
                      position: 'relative',
                      borderRight: `2px solid ${props.items.length - 1 === itemIndex ? '#ffffff' : '#DFE4E8'}`
                    }}
                  >
                    <div className={findParameter(item, parameter)?.includes('lub') ? classes.tooltipPadding : ''}>
                      {findParameter(item, parameter)}
                    </div>
                    <Tooltip type="component" data={getEngineDetails()} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  tooltipPadding: {
    padding: '0 30px',
    textAlign: 'center'
  },
  table: {
    borderTop: '2px solid #001E50',
    borderBottom: '2px solid #001E50'
  },
  td: {
    borderTop: '2px solid #DFE4E8'
  },
  selection: {
    '&:hover': {
      backgroundColor: '#001E500D'
    }
  },
  cell1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'start'
    },
    fontSize: 14,
    fontWeight: 700,
    color: '#001E50',
    borderRight: '2px solid #001E50',
    padding: '12px 16px'
  },
  cell2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 14,
    color: '#001E50',
    padding: '0 5px'
  },
  detailsTable: {
    borderCollapse: 'collapse',
    '& td': {
      borderBottom: '1px solid gray',
      padding: '5px 5px 0 5px',
      fontSize: '12px',
      fontWeight: 400
    }
  }
}));
