// @ts-nocheck
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { IDetailsSectionType, IGroup } from '../../types';

export type IPdfTechnicalsProps = {
  type: IDetailsSectionType;
  children?: React.ReactNode;
  data: IGroup;
};

export const PdfTechnicals = (props: IPdfTechnicalsProps) => {
  const widthAllCell2 = 78;
  const widthCell1 = `${100 - widthAllCell2}%`;
  const widthCell2 = `${Math.floor(widthAllCell2 / props?.data?.models?.length)}%`;

  return (
    <View style={styles.section}>
      <View style={styles.preTable}>
        <View
          style={{
            ...styles.preTableCell1,
            width: widthCell1
          }}
        >
          <Text style={{ fontFamily: 'VWHead', fontSize: '11px', fontWeight: 700 }}>{props.data.name}</Text>
        </View>
        {props?.data?.models?.map((model, index) => (
          <View
            style={{
              ...styles.preTableCell2,
              width: widthCell2,
              borderLeft: `2px solid ${index === 0 ? '#001E50' : '#DFE4E8'}`
            }}
            key={`top-${model.name}`}
          >
            <Text style={{ fontFamily: 'VWHead', fontSize: '10px', fontWeight: 700 }}>{model.name}</Text>
          </View>
        ))}
      </View>

      <View>
        {props?.data?.categories?.map((category) => (
          <View key={category?.name}>
            <View style={{ margin: '4px 0 4px 16px' }}>
              <Text style={{ ...styles.tableTitle, fontFamily: 'VWHead', fontSize: '12px' }}>{category?.name}</Text>
            </View>

            <View style={styles.table}>
              {category?.parameters.map((parameter, index) => (
                <View key={`${parameter}-${index}`} item xs={12}>
                  <View style={{ ...styles.td, borderTop: `${index === 0 ? '0px' : '2px'} solid #DFE4E8` }}>
                    <View style={{ ...styles.cell1, width: widthCell1 }}>
                      <Text>{parameter}</Text>
                    </View>
                    {props.data.items.map((item, itemIndex) => (
                      <View
                        key={`${parameter}-${index}-${item.model}`}
                        style={{
                          ...styles.cell2,
                          width: widthCell2,
                          borderLeft: `2px solid ${itemIndex === 0 ? '#001E50' : '#DFE4E8'}`
                        }}
                      >
                        <Text>{item.parameters?.find((itemParameter) => itemParameter.name === parameter)?.value}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    marginHorizontal: 24
  },
  preTable: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '2px solid #001E50'
  },
  preTableCell1: {
    display: 'flex',
    flexGrow: 1,
    padding: '5px 4px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  preTableCell2: {
    display: 'flex',
    flexGrow: 1,
    padding: '5px 8px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableTitle: {
    fontSize: 20,
    marginRight: 9,
    display: 'inline-block'
  },

  table: {
    borderTop: '2px solid #001E50',
    borderBottom: '2px solid #001E50'
  },
  td: {
    display: 'flex',
    flexDirection: 'row'
  },
  cell1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    fontSize: 10,
    color: '#001E50',
    padding: '6px 8px'
  },
  cell2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 10,
    color: '#001E50'
  }
});
