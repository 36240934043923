import { CircularProgress, Box, Grid, Container, Divider } from '@mui/material';
import { usePricelists } from '../hooks';
import config from '../configuration/config';

export function PricelistSelector() {
  const { data, isLoading } = usePricelists();
  const pricelistsUrl = (config.assetsBaseUrl ?? window.location.host) + '/pricelists/pricelists.json';

  if (!data || isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress size={70} />
      </Box>
    );

  if (process.env.NODE_ENV !== 'development') {
    return <main></main>;
  }

  return (
    <main>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <h1>Cenniki</h1>
            <Divider sx={{ marginBottom: '30px' }} />
            <div>Lista cenników załadowana z:</div>
            <a target="_blank" href={pricelistsUrl} rel="noreferrer">
              {pricelistsUrl}
            </a>
          </Grid>
          <Grid item xs={12}>
            <div>
              Aby zmienić źródło danych należy ustawić zmienną środowiskową <strong>REACT_APP_ASSETS_BASE_URL</strong> w
              pliku <strong>.env.development.local</strong> w głownym katalogu projektu. Ustawienie zmiennej z pustą
              wartością spowoduje ładowanie danych z folderu <strong>./public/pricelists</strong>
            </div>
            <Divider sx={{ marginTop: '30px' }} />
          </Grid>
          {data.map((item, index) => (
            <Grid item key={index} xs={4}>
              <a href={item.slug}>{item.name}</a>
            </Grid>
          ))}
        </Grid>
      </Container>
    </main>
  );
}
