import { Fragment } from 'react';
import { CircularProgress, Theme, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  DetailsSection,
  Calculator,
  IDetailsSectionProps,
  HeaderSection,
  OfferSection,
  BoxSection,
  Form,
  OpenNbabSpeedDial,
  Gallery
} from '../components';
import { ModelsAndFeatures, Dimensions, ExteriorsAndInteriors, Wheels, Technicals } from '../components/DetailsSection';
import { useAppContext } from '../hooks/useAppContext';
import { updateMetaTags } from '../helpers/headTags';
import { ISection, ISectionType, IDetailsSectionType, IGroup } from '../types';

export function Main() {
  const classes = useStyles();
  const { data, isLoading } = useAppContext();

  if (!data || isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress size={70} />
      </Box>
    );

  updateMetaTags(data);
  const getDetailSectionHeadings = (type: IDetailsSectionType) => {
    let section = data.sections.find((detail) => detail.type === type) as IDetailsSectionProps;

    if (!section) return null;

    if (type === 'technicals') {
      section.subtitle = data.technicalDataSubline;
    }

    return section;
  };

  const getSectionData = (type: ISectionType) => data.sections?.find((sections) => sections.type === type) as ISection;
  const getSectionsData = (type: ISectionType) =>
    data.sections.filter((sections) => sections.type === type) as ISection[];
  const getGroupsData = (type: IDetailsSectionType) =>
    data.sections?.find((sections) => sections.type === type)?.groups as IGroup[];

  const defaultModelsGroup = getSectionData('models')
    ?.groups?.filter((group) => {
      const isFinded = group.items.find((item) => item.defaultForFinancialProduct)?.defaultForFinancialProduct;
      return isFinded;
    })
    ?.at(0);

  const defaultForFinancialProductEngineCode = defaultModelsGroup?.items?.find(
    (item) => item.defaultForFinancialProduct
  )?.baseCode;

  const detailsSections = data.sections
    .map((section) => section.type)
    .filter((type) => type !== 'offerbox' && type !== 'box');

  return (
    <main className={classes.root}>
      <OpenNbabSpeedDial nbab={data.nbab} />
      {data.defaultFinancialProduct && (
        <Calculator
          defaultFinancialProduct={data.defaultFinancialProduct}
          defaultForFinancialProductEngineCode={defaultForFinancialProductEngineCode}
        />
      )}

      <HeaderSection data={data} />
      {!!getSectionData('offerbox') && (
        <OfferSection type="offerbox" data={getSectionData('offerbox')}>
          {getSectionsData('box')?.map((sectionData, index) => (
            <BoxSection type="box" key={index} data={sectionData} />
          ))}
        </OfferSection>
      )}

      {detailsSections
        .filter((type): type is IDetailsSectionType => {
          return [
            'models',
            'exteriors',
            'interiors',
            'wheels',
            'promoFeatures',
            'features',
            'accessories',
            'technicals',
            'dimensions'
          ].includes(type);
        })
        .map((type) => {
          const sectionHeadings = getDetailSectionHeadings(type);
          if (!sectionHeadings) return null;

          let section = null;

          if (type === 'models' || type === 'promoFeatures' || type === 'features') {
            section = (
              <DetailsSection {...sectionHeadings}>
                <ModelsAndFeatures type={type} data={getSectionData(type)} />
              </DetailsSection>
            );
          } else if (type === 'exteriors' || type === 'interiors') {
            section = (
              <DetailsSection {...sectionHeadings}>
                <ExteriorsAndInteriors type={type} data={getSectionData(type)} />
              </DetailsSection>
            );
          } else if (type === 'wheels') {
            section = (
              <DetailsSection {...sectionHeadings}>
                <Wheels type={type} data={getSectionData(type)} />
              </DetailsSection>
            );
          } else if (type === 'technicals') {
            section = (
              <DetailsSection {...sectionHeadings}>
                {getGroupsData(type).map((group, index) => (
                  <Fragment key={`${type}-${index}`}>
                    <Technicals type={type} data={group} />
                  </Fragment>
                ))}
              </DetailsSection>
            );
          } else if (type === 'dimensions') {
            section = (
              <DetailsSection {...sectionHeadings}>
                <Dimensions data={getSectionData(type)} />
              </DetailsSection>
            );
          } else if (type === 'accessories') {
            section = !!getSectionData(type) ? (
              <DetailsSection type={type} title="Wybrane akcesoria">
                <Gallery type={type} items={getSectionData(type)?.items} />
              </DetailsSection>
            ) : null;
          }

          return <Fragment key={type}>{section}</Fragment>;
        })}

      <Form title={data.modelName} />
    </main>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '80px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '0'
    }
  }
}));
